export function validateNicknameInput(name) {
  const arr = name.split(/\s|,|\.|;/);
  const errWordLength = [];
  arr.forEach((item) => {
    if (item.length < 2) {
      errWordLength.push(item);
      return;
    } else {
      return item;
    }
  });
  if (errWordLength.length > 0) {
    return "Слово має складатися хоча б з 2 літер";
  }
  // Перевірка на наявність лише букв латинського або кириличного алфавіту та пробіли
  // if (!/^[A-Za-zА-Яа-яЁёІіЇїЄє\s]+$/.test(name)) {
  if (!/^[A-Za-zА-Яа-яЁёІіЇїЄє\s'`"]+$/.test(name)) {
    return "Недопустимі символи";
  }

  // Перевірка на довжину
  if (name.length < 5) {
    return "Має бути від 5 символів";
  }

  if (name.length > 48) {
    return "Має бути до 48 символів";
  }

  if (arr.length < 2) {
    return "Має складатися хоча б з 2 слів";
  }

  return null; // Перевірка успішна
}

export const transformNicknameUppercase = (e, setValue) => {
  const { name, value } = e.target;

  const words = value
    .split(/\s|,|\.|;/)
    ?.map((item) => item.charAt(0).toUpperCase() + item.slice(1)); // Розділення тексту на слова за заданими роздільниками
  const formattedValue = words.join(" "); // Повернення розділених слів через пробіл
  setValue(name, formattedValue); // Встановлення нового значення поля
};
