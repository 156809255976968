import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function CustomAdapter(options) {
  const adapter = new AdapterDayjs(options);

  const constructDayObject = (day) => ({
    charAt: () => day,
  });

  return {
    ...adapter,

    getWeekdays() {
      const customWeekdays = adapter.getWeekdays();

      const newCustomWeekdays = customWeekdays.map(
        (day) => day.charAt(0).toUpperCase() + day.slice(1)
      );

      return newCustomWeekdays.map((day) => constructDayObject(day));
    },
  };
}
