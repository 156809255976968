import styled from "@emotion/styled";

export const Box = styled.ul`
  display: flex;
  width: 100%;
  max-width: 327px;
  /* gap: 5px; */
  justify-content: space-between;

  margin-bottom: 24px;
  /* margin-left: auto;
  margin-right: auto; */

  @media screen and (min-width: 375px) {
    width: 327px;
    gap: 5px;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 1440px) {
    margin-bottom: 40px;
  }
`;

export const RadioContainer = styled.label`
  position: relative;
  display: block;
  padding: 8px 8px 8px 24px;
  border-radius: 24px;
  border: 1px solid #fbb336;
  &:focus,
  &:hover {
    border: 1px solid #647f29;
  }

  @media screen and (min-width: 335px) {
    padding-left: 32px;
  }
  @media screen and (min-width: 375px) {
    width: 161px;
    padding-left: 40px;
  }
`;

export const RadioLable = styled.span`
  display: flex;
  align-items: center;

  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: 14px;
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 1.25;
  color: ${(p) => p.theme.palette.primary.text};
  letter-spacing: -0.04em;

  @media screen and (min-width: 350px) {
    letter-spacing: 0;
  }
  @media screen and (min-width: 350px) {
    letter-spacing: -0.03em;
  }

  &::before {
    content: "";
    box-sizing: border-box;
    display: block;
    position: absolute;

    top: calc(31%-1vw);
    left: 8px;

    width: 4vw;
    height: 4vw;

    border: 1px solid #85b53a;
    border-radius: 50%;

    @media screen and (min-width: 375px) {
      top: 4.5px;

      width: 24px;
      height: 24px;
    }
  }
`;

export const RadioInput = styled.input`
  display: none;
  &:checked + span:before {
    background: #85b53a;
  }
`;
