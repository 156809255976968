import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

import { ReactComponent as Card } from "../../images/icon-cabinet/card.svg";
import { ReactComponent as Cash } from "../../images/icon-cabinet/cash.svg";
import { ReactComponent as NonePay } from "../../images/icon-cabinet/nonePay.svg";

import { getHistory } from "redux/history/operation";
import { selectHistory } from "redux/history/selector";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/uk";
import {
  ButtonClose,
  IconRight,
  UserBox,
  UserName,
  UserMoneyList,
  UserMoneyItem,
  UserMoneySpan,
  UserNoAppointments,
  HistoryBox,
  HistoryHeadSpan,
  HistoryHead,
  HistoryList,
  HistoryItem,
  HistoryItemSpan,
  IconBtn,
  IconPen,
} from "./AdminUsersHistory.styled";
import DeleteBtn from "components/DeleteBtn/DeleteBtn";
dayjs.extend(utc);

const AdminUsersHistory = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const now = dayjs();

  useEffect(() => {
    dispatch(getHistory(userId));
  }, [dispatch, userId]);

  const historyArr = useSelector(selectHistory);

  const newHistoryArr = historyArr.map((app) =>
    now - dayjs(app.day) < 0
      ? { ...app, future: true }
      : { ...app, future: false }
  );

  const dayUserBirthday = dayjs(historyArr[0]?.userBirthday).format(
    "DD.MM.YYYY"
  );
  const backLink = location.state?.from ?? "/";

  return (
    <div>
      <ButtonClose to={backLink}>
        <IconRight />
      </ButtonClose>
      <>
        {newHistoryArr.length > 0 ? (
          <HistoryBox>
            <UserBox>
              <div>
                <UserName>{newHistoryArr[0]?.user}</UserName>
                <UserName style={{ color: "#404731" }}>
                  {dayUserBirthday}
                </UserName>
              </div>
              <UserMoneyList>
                <UserMoneyItem>
                  <UserMoneySpan>Депозит:</UserMoneySpan>
                  {newHistoryArr[0].userDeposit} грн.
                </UserMoneyItem>
                <UserMoneyItem>
                  <UserMoneySpan>Баланс:</UserMoneySpan>
                  {newHistoryArr[0].userBalance} грн.
                </UserMoneyItem>
              </UserMoneyList>
            </UserBox>

            <HistoryHead>
              <HistoryHeadSpan>Дата</HistoryHeadSpan>
              <HistoryHeadSpan>Час</HistoryHeadSpan>
              <span>Оплата</span>
            </HistoryHead>
            <HistoryList>
              {newHistoryArr.map((app) => {
                return (
                  <HistoryItem key={app._id} isFuture={app.future}>
                    <HistoryItemSpan>
                      {dayjs(app.day).format("DD.MM")}
                    </HistoryItemSpan>
                    <HistoryItemSpan>
                      {`${dayjs.utc(app.startTime).format("HH:mm")} - ${dayjs
                        .utc(app.endTime)
                        .format("HH:mm")}`}
                    </HistoryItemSpan>
                    {app.payedType === "none" ? (
                      <NonePay />
                    ) : app.payedType === "cash" ? (
                      <Cash />
                    ) : (
                      <Card />
                    )}
                    <HistoryItemSpan>{app.payedSum}</HistoryItemSpan>

                    <IconBtn
                      to={`/cabinet/finance/${userId}/${app._id}`}
                      state={{ from: location }}
                    >
                      <IconPen />
                    </IconBtn>
                  </HistoryItem>
                );
              })}
            </HistoryList>
          </HistoryBox>
        ) : (
          <UserNoAppointments>
            У данного пацієнта немає записів
          </UserNoAppointments>
        )}
      </>
      <DeleteBtn
        userId={userId}
        // userName={newHistoryArr[0]?.user}
        backLink={backLink}
      />
    </div>
  );
};

export default AdminUsersHistory;
