import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams, useLocation, Navigate } from "react-router-dom";
import { getAppointments } from "redux/appointments/operation";
import { selectAppointments } from "redux/appointments/selector";
import { selectUser } from "redux/auth/selector";

import Loader from "components/Loader/Loader";

import { ButtonClose, IconRight } from "./UpdateUserAppointmentDetails.styled";
import UpdateUserAppForm from "./UpdateUserAppForm";

const UpdateUserAppointmentDetails = () => {
  const { appointmentId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const backLinkHref = location.state?.from;

  useEffect(() => {
    dispatch(getAppointments());
  }, [dispatch]);
  const allAppointments = useSelector(selectAppointments);
  const userRole = useSelector(selectUser);

  // для юзера блокування для цієї сторінки і відправлення його на початкову
  if (userRole.role === "client") {
    return <Navigate to={"/"} />;
  }

  const electAppointment = allAppointments.filter(
    (app) => app._id === appointmentId
  );

  const isBackLink = backLinkHref ? backLinkHref : "/cabinet/reception";
  return (
    <>
      {electAppointment.length === 0 || !electAppointment[0].user ? (
        <Loader />
      ) : (
        <>
          <ButtonClose to={isBackLink}>
            <IconRight />
          </ButtonClose>
          <UpdateUserAppForm
            electAppointment={electAppointment}
            turnBack={isBackLink}
          />
        </>
      )}
    </>
  );
};

export default UpdateUserAppointmentDetails;
