import { createSlice } from "@reduxjs/toolkit";
import {
  getAppointments,
  addAppointment,
  deleteAppointment,
  setIsNewAppointmentToFalse,
  appointmentUpdate,
} from "./operation";

const initialStateAppointments = {
  appointments: [],
  isLoading: false,
  error: null,
  isNewAppointment: false,
};

const appointmentsSlice = createSlice({
  name: "appointments",
  initialState: initialStateAppointments,
  extraReducers: (builder) =>
    builder
      .addCase(getAppointments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getAppointments.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addAppointment.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.appointments.push(action.payload);
        state.isNewAppointment = true;
      })
      .addCase(addAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(appointmentUpdate.fulfilled, (state, action) => {
        state.isNewAppointment = true;
      })
      .addCase(setIsNewAppointmentToFalse, (state, action) => {
        state.isNewAppointment = false;
      })
      .addCase(deleteAppointment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const { appointmentId } = action.payload;

        state.appointments = state.appointments.filter(
          (appointment) => appointment._id !== appointmentId
        );
      })
      .addCase(deleteAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      }),
});

export const appointmentsReducer = appointmentsSlice.reducer;
