import styled from "@emotion/styled";
import { ReactComponent as Filter } from "../../images/filter.svg";
import { ReactComponent as Save } from "../../images/save.svg";
import { ReactComponent as Telegram } from "../../images/contacts/telegramDeposit.svg";
import { ReactComponent as Viber } from "../../images/contacts/viberDeposit.svg";
import { ReactComponent as MoreVertical } from "../../images/icon-cabinet/more-vertical.svg";
import { Link } from "react-router-dom";

export const Box = styled.div`
  width: 100%;
  max-width: 327px;
  margin-left: auto;
  margin-right: auto;

  @media ${(p) => p.theme.device.tablet} {
    max-width: 414px;
    padding-bottom: 172px;
  }

  @media ${(p) => p.theme.device.desktop} {
    max-width: 522px;
    padding-bottom: 146px;
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
`;

export const TelegramIcon = styled(Telegram)`
  fill: #2aabee;
`;

export const ViberIcon = styled(Viber)`
  fill: #665cac;
`;

export const Button = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  opacity: 0.6;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    opacity: 1;
  }
`;
export const ButtonMore = styled(Link)`
  width: 36px;
  height: 24px;
  border: none;
  outline: none;
  background-color: transparent;
`;

export const InputContainerFilter = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  margin-bottom: 24px;
`;

export const InputStyle = styled.input`
  display: block;
  cursor: pointer;
  width: 100%;
  height: 48px;
  padding: 12px;
  margin-top: 48px;

  font-family: ${(p) => p.theme.fonts.Manrope};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: ${(p) => p.theme.fontSizes[4]};
  line-height: 1.1;
  text-align: start;
  background-color: ${(p) => p.theme.palette.primary.white};
  color: ${(p) => p.theme.palette.primary.text};

  border-radius: 24px;
  border: 1px solid #d3e5b8;

  &:focus,
  &:hover {
    border: ${(props) =>
      props.isDeposit ? "0.5px solid #85b53a" : "0.5px solid #fbb336"};
    outline: none;
  }

  &::placeholder {
    font-family: ${(p) => p.theme.fonts.Manrope};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    font-size: ${(p) => p.theme.fontSizes[4]};
    line-height: 22px;
    color: ${(p) => p.theme.palette.primary.main};
  }

  @media ${(p) => p.theme.device.tablet} {
    margin-top: 104px;
  }
`;

export const IconFilter = styled(Filter)`
  position: absolute;
  top: 12px;
  right: 12px;
  display: inline;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const IconSave = styled(Save)`
  position: absolute;
  top: 16px;
  right: 8px;
  display: inline;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const More = styled(MoreVertical)`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const List = styled.ul`
  width: 100%;
  height: 458px;
  margin-top: 52px;
  margin-bottom: 0;
  overflow-x: hidden;
`;

export const BoxItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 168px;

  @media ${(p) => p.theme.device.tablet} {
    width: 184px;
  }
`;

export const Item = styled.li`
  margin-bottom: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 16px;
  padding-bottom: 8px;
  border-bottom: ${(props) =>
    !props.isDeposit ? "0.5px solid #85b53a" : "0.5px solid #fbb336"};

  &::before {
    content: "";
    position: absolute;
    top: 40%;
    left: 0;
    width: 8px;
    height: 8px;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: ${(props) => (props.isDeposit ? "#85b53a" : "#fbb336")};
  }
`;

export const Name = styled.p`
  font-family: ${(p) => p.theme.fonts.Barlow};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: ${(p) => p.theme.fontSizes[4]};
  line-height: 20px;
  text-align: start;
  background-color: transparent;
  color: ${(p) => p.theme.palette.primary.text};
`;

export const Number = styled.p`
  display: flex;
  align-items: center;
  font-family: ${(p) => p.theme.fonts.Barlow};
  font-weight: ${(p) => p.theme.fontWeights.semibold};
  font-size: ${(p) => p.theme.fontSizes[4]};
  line-height: 16px;
  gap: 12px;
  text-align: start;
  background-color: transparent;
  color: ${(p) => p.theme.palette.primary.text};
`;

export const Notifications = styled.p`
  font-family: ${(p) => p.theme.fonts.Barlow};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: ${(p) => p.theme.fontSizes[4]};
  line-height: 20px;
  text-align: start;
  background-color: transparent;
  color: ${(p) => p.theme.palette.primary.text};
`;

export const InputContainer = styled.div`
  position: relative;
  width: 96px;
  height: 56px;
  border-radius: 16px;
`;

export const Input = styled.input`
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 8px;
  padding-left: 12px;
  border-radius: 16px;
  border: none;
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: ${(p) => p.theme.fontSizes[4]};
  line-height: 24px;
  background-color: ${(p) => p.theme.palette.primary.white};
  color: ${(p) => p.theme.palette.primary.text};
  box-shadow: ${(p) => p.theme.shadows[0]};
`;

export const SearchNoResult = styled.p`
  display: flex;
  width: 100%;
  max-width: 327px;
  height: 48px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;

  margin-top: 24px;
  margin-bottom: 46px;
  margin-left: auto;
  margin-right: auto;

  text-align: center;

  color: #647f29;
  font-size: 16px;
  font-weight: 600;
  font-family: Manrope;

  border-radius: 24px;
  background: #fbfbfb;
  box-shadow: 0px 4px 11px 0px rgba(100, 127, 41, 0.15),
    4px 0px 11px 0px rgba(100, 127, 41, 0.15);

  line-height: 1.6;
`;
