import { selectUser } from "redux/auth/selector";
// import { addAppointment } from "redux/appointments/operation";
import { useRadioBtn } from "context/radioBtnContext";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

export const useAppointmentsDispatch = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const {
    dayValue,
    appointmentRadioChoice,
    radioChoice,
    appointmentType,
    idAppointment,
  } = useRadioBtn();

  const dispatchAppointment = (propsFunction, userId = user._id) => {
    const appointmentDay = dayjs(dayValue).format("DD.MM.YYYY");
    const [appointmentStart, appointmentEnd] =
      appointmentRadioChoice.split("-");

    const appointment =
      radioChoice === "secondary" ? "secondaryLong" : radioChoice;

    const payload = {
      user: userId,
      day: appointmentDay,
      appointmentType: appointmentType,
      appointment,
      startTime: `${appointmentDay} ${appointmentStart.trim()}`,
      endTime: `${appointmentDay} ${appointmentEnd.trim()}`,
    };
    const data = idAppointment
      ? { ...payload, id: idAppointment }
      : { ...payload };
    // console.log(data);
    dispatch(propsFunction({ ...data }));
  };

  return dispatchAppointment;
};
