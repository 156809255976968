export const sharedStyles = {
  container: (baseStyles) => ({
    ...baseStyles,
    paddingLeft: "0",
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,

    width: "100%",
    border: "none",
    borderRadius: "0",
    boxShadow: state.isFocused ? "0" : "0",
    backgroundColor: "transparent",
    borderBottom: "1px solid #FBB336",

    cursor: "pointer",

    color: "#404731",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "1.5",

    "&:hover": {
      borderBottom: "inherite",
    },

    "& .css-1fdsijx-ValueContainer": {
      padding: "0",
    },

    "& .css-hlgwow": {
      padding: "0",
    },

    "& .css-tj5bde-Svg": {
      padding: "0",
      fill: "#647f29",
      width: "24px",
      height: "24px",
    },
    "& .css-1u9des2-indicatorSeparator": {
      width: "0",
    },
  }),
  option: (baseStyles, { data, isDisabled, isFocused, isSelected }) => ({
    ...baseStyles,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? "#D3E5B8"
      : isFocused
      ? "#D3E5B8"
      : undefined,
  }),
};
