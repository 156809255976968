import React from "react";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "./LogoUser.styled";

const LogoUser = () => {
  const { isLoggedIn, user } = useAuth();
  const isAllUserData = user.phone && user.birthday;

  return (
    <>
      {!isLoggedIn && !isAllUserData && (
        // <LogoButton
        //   type="button"
        //   style={{ border: "1px solid rgba(100, 127, 41, 1)" }}
        // >
        <Link to={"/auth/signin"}>Увійти</Link>
        // </LogoButton>
      )}
    </>
  );
};

export default LogoUser;
