import * as React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CustomAdapter from "helpers/CustomDateAdapter";
import dayjs from "dayjs";
import { ThemeProvider } from "@mui/material/styles";
import { customTheme } from "./MuiDialogTheme";

const MuiBirthdayTimePicker = ({
  timePickerValue,
  setTimePickerValue,
  pickerStyles,
  setValueOpen,
}) => {
  return (
    <ThemeProvider theme={customTheme}>
      <LocalizationProvider dateAdapter={CustomAdapter} adapterLocale="uk">
        <DemoContainer
          components={["MobileDatePicker"]}
          // paddingTop: 8px прибраний)
          sx={{
            paddingTop: 0,
            "@media (min-width: 1200px)": {
              flexDirection: "column",
            },
          }}
        >
          <DemoItem>
            <MobileDatePicker
              views={["year", "month", "day"]}
              value={timePickerValue}
              onChange={setTimePickerValue}
              // можливо прописувати так для 2 різних форм, бо setValueOpen хтось не передає
              onOpen={setValueOpen && (() => setValueOpen(true))}
              name="birthday"
              id="birthday"
              disableFuture
              minDate={dayjs("01.01.1920")}
              maxDate={dayjs().subtract(2, "month")} // від поточного числа на мінус 2 місяці береться
              // errorText="error text on input"
              // errorStyle={{ color: "red" }}
              sx={{
                ...pickerStyles,
                fontFamily: '"Manrope",sans-serif',
                fontSize: "13px",
              }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default MuiBirthdayTimePicker;
