import axios from "axios";
import { createAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// axios.defaults.baseURL = "https://dietary-rest-api.yurgo.fun/api";
axios.defaults.baseURL = process.env.REACT_APP_BACK_BASE_URL;
// axios.defaults.baseURL = "http://localhost:3005/api";

// const BACK_URL = process.env.BACK_URL;
// console.log("CL: ~ file: operation.js:8 ~ BACK_URL:", BACK_URL);

const token = {
  set(token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
  unset() {
    axios.defaults.headers.common.Authorization = "";
  },
};

export const register = createAsyncThunk(
  "auth/register",
  async (credentials, thunkAPI) => {
    try {
      const r = await axios.post("/auth/register", credentials);
      token.set(r.data.user.token);

      return r.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logIn = createAsyncThunk(
  "auth/login",
  async (credentials, thunkAPI) => {
    try {
      const r = await axios.post("/auth/login", credentials);
      token.set(r.data.user.token);
      return r.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const setGoogleData = createAsyncThunk(
  "auth/login",
  async (credentials, thunkAPI) => {
    try {
      token.set(credentials.user.token);
      return credentials;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logOut = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    await axios.post("/auth/logout");
    token.unset();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const refreshCurrentUser = createAsyncThunk(
  "auth/refresh",
  async (_, thunkAPI) => {
    const state = await thunkAPI.getState();
    const persistedToken = await state.auth.token;

    if (!persistedToken) {
      return thunkAPI.rejectWithValue("Unable to fetch user");
    }

    token.set(persistedToken);
    try {
      const r = await axios.get("/auth/current");
      r.data.currentUser.token = persistedToken;
      return r.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const userChangePassword = createAsyncThunk(
  "auth/change",
  async (credentials, thunkAPI) => {
    try {
      const r = await axios.post("/auth/change", credentials);
      token.set(r.data.user.token);

      toast.success("Ваш пароль успішно змінено!");
      return r.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const userUpdate = createAsyncThunk(
  "auth/userUpdate",
  async (credentials, thunkAPI) => {
    try {
      const r = await axios.patch("/user", credentials);
      toast.success("Дані успішно оновлено!");
      return r.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const userUpdateByAdmin = createAsyncThunk(
  "auth/userUpdateByAdmin",
  async (credentials, thunkAPI) => {
    try {
      const r = await axios.patch("/user/by-admin", credentials);
      toast.success("Змінено!");
      return r.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const totalLogOut = createAction("user/totalLogOut");

export const userDeleteByAdmin = createAsyncThunk(
  "auth/userDeleteByAdmin",
  async (userId, thunkAPI) => {
    try {
      const r = await axios.delete(`/user/by-admin/${userId}`);
      toast.success("Пацієнт видалений!");
      return r.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
