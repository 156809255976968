import { createTheme } from "@mui/material/styles";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#85B53A",
      light: "#00FF00", //green
      dark: "#85B53A",
      contrastText: "#FFFFFF",
      text: "#FFFF99",
    },
    secondary: {
      main: "#FFFF99", //yellow
    },
  },
  typography: {
    fontFamily: [
      "'DM Sans', sans-serif, 'Comfortaa', cursive,'Manrope', sans-serif,'Inter', sans-serif, 'Barlow', sans-serif",
    ].join(","),
  },
  components: {
    // MuiPickersLayout: {
    //   styleOverrides: {
    //     // paperScrollPaper: { display: "none" },
    //     root: {
    //       width: "calc(100% - 48px)",
    //     },
    //   },
    // },
    MuiDialog: {
      styleOverrides: {
        // paperScrollPaper: { display: "none" },
        // rounded: {
        //   width: "calc(100% - 48px)",
        // },
        paperWidthSm: {
          width: "calc(100vw - 72px) !important",
        },
        paper: {
          // width: "calc(100% - 148px)  !important",

          // width: "calc(100% - 148px)",
          // width: "100%",
          // overflow: "hidden",

          backgroundColor: "#FBFBFB",
          borderRadius: "24px",
          border: "1px solid #E8E8E1",
          color: "#85B53A",
          fill: "#647F29",
          fontSize: 14,

          minWidth: "320px",
          maxWidth: "350px",

          fontWeight: 400,
          lineHeight: 1.25,
          "@media (min-width: 375px)": {
            fontSize: 17,

            fontWeight: 600,
          },
        },
      },
    },
    // MuiDialogContent: {
    //   styleOverrides: {
    //     root: {
    //       width: "calc(100% - 58px)",

    //       "@media (min-width: 375px)": {
    //         width: "326px",
    //       },
    //     },
    //   },
    // },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: "#647F29",
          fontSize: 14,
          fontWeight: 200,

          "&.Mui-disabled:not(.Mui-selected)": {
            color: "#D3E5B8", // Add your desired color here
          },
          "@media (min-width: 375px)": {
            fontSize: 20,
            fontWeight: 200,
          },
        },
        today: {
          "&:not(.Mui-selected)": {
            border: " 1px solid #FBB336",
          },
        },

        // disabled: {
        //   color: "#D3E5B8",
        // },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          color: "#85B53A",

          fontSize: 13,
          fontWeight: 600,
          lineHeight: 1.38 /* 138.462% */,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: "#647F29",
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        root: {
          color: "#647F29",
        },
      },
    },
    MuiPickersMonth: {
      styleOverrides: {
        root: {
          color: "#647F29",
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          display: "none",
        },
      },
    },
  },
});
