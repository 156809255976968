import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import { useFormik } from "formik";
import Select from "react-select";

import useGetHoursList from "hooks/useGetHoursList ";
import { selectType, selectPayType } from "utils/selectType";
import { dbDictionary } from "utils/dbDictionary";

import {
  appointmentUpdate,
  deleteAppointment,
  getAppointments,
} from "redux/appointments/operation";
import { selectDates } from "redux/dates/selector";

import ModalWindow from "components/ModalWindow/ModalWindow";
import { PopUpNotification } from "components/PopUp/PopUpNotification";
import ModalFalseAdmin from "components/ModalWindow/ModalFalseAdmin";
import MuiTimePickerViews from "components/MuiTimePicker/MuiTimePicker";

import { IconPensil, Input } from "../UpdateUserForm/UpdateUserForm.styled";

import {
  FormStyle,
  Text,
  ContainerBalance,
  InputContainer,
  Button,
  ButtonNone,
  UserNick,
  IconGrommetMoney,
} from "./UpdateUserAppointmentDetails.styled";

import { sharedStyles } from "styles/stylesToSelect";

import utc from "dayjs/plugin/utc";
import "dayjs/locale/uk";
import { useRadioBtn } from "context/radioBtnContext";
import ModalBackdrop from "components/Modal/Modal";

dayjs.extend(utc);

const UpdateUserAppForm = ({ electAppointment, turnBack }) => {
  const navigate = useNavigate();

  const { setDayValue } = useRadioBtn();

  const dispatch = useDispatch();
  const nickName = electAppointment[0]?.user.nickName;

  const appointment = dbDictionary[electAppointment[0]?.appointment];
  const startTime = dayjs.utc(electAppointment[0]?.startTime).format("HH:mm");
  const endTime = dayjs.utc(electAppointment[0]?.endTime).format("HH:mm");
  const payedSum = electAppointment[0]?.payedSum;
  const payedType = electAppointment[0]?.payedType;
  const balance = electAppointment[0]?.user.balance;

  const selectValue = (arr, findEl) => {
    return arr.find((el) => el.value === findEl);
  };

  const [day, setDay] = useState(
    electAppointment[0]?.day ? dayjs.utc(electAppointment[0]?.day) : null
  );
  useEffect(() => {
    setDayValue(day);
  }, [day, setDayValue]);

  const [appointType, setAppointType] = useState(
    selectValue(selectType, electAppointment[0]?.appointmentType)
  );
  const [payType, setPayType] = useState(selectValue(selectPayType, payedType));

  const [show, setShow] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const toggleModal = () => {
    setShow(!show);
  };

  const handleConfirm = () => {
    dispatch(deleteAppointment(electAppointment[0]?._id));
    setSuccessModal(true);
    toggleModal();

    setTimeout(() => {
      navigate(turnBack, { replace: true });
    }, 2000);
  };

  const allDates = useSelector(selectDates);

  const selectDay = allDates.find(
    (d) => d.day === dayjs.utc(day).format("MM-DD-YYYY")
  );

  let hoursList = useGetHoursList(
    day,
    selectDay,
    electAppointment[0].appointment,
    appointType.value,
    true
  );

  if (hoursList.length === 0) {
    hoursList = [
      {
        value: `${startTime} - ${endTime}`,
        label: `${startTime} - ${endTime}`,
        isDisabled: true,
      },
    ];
  }

  const [selectDefaultTime, setSelectDefaultTime] = useState(
    selectValue(hoursList, `${startTime} - ${endTime}`)
  );

  const initialValues = {
    payedSum: payedSum ? payedSum : "0",
  };

  const formik = useFormik({
    initialValues,

    onSubmit: async (values) => {
      try {
        const timeArr = selectDefaultTime.value.split("-");
        const startTime = `${dayjs
          .utc(day)
          .format("DD.MM.YYYY")} ${timeArr[0].trim()}`;
        const endTime = `${dayjs
          .utc(day)
          .format("DD.MM.YYYY")} ${timeArr[1].trim()}`;

        const payload = {
          id: electAppointment[0]?._id,
          user: electAppointment[0]?.user._id,
          appointmentType: appointType.value,
          day: dayjs.utc(day).format("DD.MM.YYYY"),
          startTime,
          endTime,
          payedSum: values.payedSum,
          payedType: payType.value,
        };

        const handleDispatch = async () => {
          await dispatch(appointmentUpdate(payload));
          dispatch(getAppointments());
          navigate(turnBack, { replace: true });
        };
        handleDispatch();
      } catch (error) {}
    },
  });

  const changeAppointType = (e) => {
    setAppointType(e);
    setSelectDefaultTime("");
    setDay(null);
  };

  const changeDay = (e) => {
    setSelectDefaultTime("");
    setDay(e);
  };

  const changePayType = (e) => {
    setPayType(e);
  };

  const { errors, values, handleSubmit, handleBlur, handleChange } = formik;

  return (
    <>
      <ContainerBalance>
        <Text>
          <IconGrommetMoney />
          {balance} грн.
        </Text>
      </ContainerBalance>

      <FormStyle onSubmit={handleSubmit}>
        <InputContainer>
          <UserNick>{nickName}</UserNick>
        </InputContainer>
        <InputContainer>
          <UserNick>{appointment}</UserNick>
        </InputContainer>
        <Select
          options={selectType}
          value={appointType}
          onChange={(e) => changeAppointType(e)}
          styles={{ ...sharedStyles }}
        />

        <InputContainer
          style={{
            borderBottom: "1px solid #FBB336",
            marginBottom: "24px",
            marginTop: "24px",
          }}
        >
          <MuiTimePickerViews
            timePickerValue={day}
            setTimePickerValue={changeDay}
            appointmentType={appointType.value}
          />
          <IconPensil style={{ top: "10px", right: "10px" }} />
        </InputContainer>

        {day && (
          <Select
            placeholder="Виберіть час"
            options={hoursList}
            value={selectDefaultTime}
            onChange={(e) => setSelectDefaultTime(e)}
            styles={{ ...sharedStyles }}
          />
        )}
        <InputContainer style={{ marginTop: "24px" }}>
          <Input
            id="payedSum"
            name="payedSum"
            value={values.payedSum}
            type="number"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
          />
          <IconPensil style={{ top: "10px", right: "10px" }} />
        </InputContainer>
        <Select
          options={selectPayType}
          value={payType}
          onChange={(e) => changePayType(e)}
          styles={{ ...sharedStyles }}
        />
        <Button type="submit" disabled={Object.values(errors).length !== 0}>
          Зберегти
        </Button>
        <ButtonNone type="button" onClick={toggleModal}>
          Відмінити прийом
        </ButtonNone>
        {show && (
          <ModalBackdrop onClose={toggleModal}>
            <ModalWindow
              cancelName="Ні"
              confirmName="Так"
              onConfirm={handleConfirm}
              onCancel={toggleModal}
              onCloseModal={toggleModal}
              text="хочете відмінити прийом?"
            />
          </ModalBackdrop>
        )}
        {successModal && (
          <PopUpNotification timeout={2000}>
            <ModalFalseAdmin />
          </PopUpNotification>
        )}
      </FormStyle>
    </>
  );
};

export default UpdateUserAppForm;
