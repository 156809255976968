import dayjs from "dayjs";
import { createTimeList } from "helpers/createTimeList";
import { useSelector } from "react-redux";
import { selectAppointments } from "redux/appointments/selector";
import { timeToMin } from "helpers/changeInTime";
import { useMemo } from "react";

const useGetHoursList = (
  value,
  selectDay,
  radioChoice,
  appType,
  isAdmin = false
) => {
  const appointments = useSelector(selectAppointments);

  const formattedDate = dayjs(value).format("DD-MM-YYYY");
  const appointDay = useMemo(
    () =>
      appointments
        .filter((d) => dayjs(d.day).format("DD-MM-YYYY") === formattedDate)
        .map((d) => {
          return {
            startTime: timeToMin(
              `${dayjs.utc(d.startTime).hour()}:${dayjs
                .utc(d.startTime)
                .minute()}`
            ),
            endTime: timeToMin(
              `${dayjs.utc(d.endTime).hour()}:${dayjs.utc(d.endTime).minute()}`
            ),
          };
        }),
    [appointments, formattedDate]
  );

  let hoursList = [];
  const timeOfvisit = {
    primary: 60,
    secondaryLong: 30,
    secondary: 30,
    secondaryShot: 30,
  };

  if (selectDay?.appointmentsStart) {
    hoursList = createTimeList(
      selectDay,
      appointDay,
      timeOfvisit[radioChoice],
      isAdmin
    );
  }

  return hoursList;
};

export default useGetHoursList;
