import React from "react";
import {
  Title,
  BoxUl,
  BoxSchedule,
  Pdays,
  UlList,
  UlListOff,
  IconUsers,
  IconTelephone,
} from "./Schedule.styled";

const Schedule = () => {
  return (
    <>
      <BoxSchedule>
        <Title>Графік прийомів:</Title>
        <BoxUl>
          <UlList>
            Офлайн <IconUsers />
            <li>
              <Pdays>
                Середа, Четвер
                <br />
                <span>10:00</span>
                <span> - </span>
                <span>18:00</span>
              </Pdays>
            </li>
            <li>
              <Pdays>
                Субота
                <br />
                <span>09:00</span>
                <span> - </span>
                <span>15:00</span>
              </Pdays>
            </li>
          </UlList>
          <UlListOff>
            Онлайн <IconTelephone />
            <li>
              <Pdays>
                Вівторок
                <br />
                <span>14:00</span>
                <span> - </span>
                <span>20:00</span>
              </Pdays>
            </li>
            <li>
              <Pdays>
                П'ятниця
                <br />
                <span>09:00</span>
                <span> - </span>
                <span>15:00</span>
              </Pdays>
            </li>
          </UlListOff>
        </BoxUl>
      </BoxSchedule>
    </>
  );
};

export default Schedule;
