import { createSlice } from "@reduxjs/toolkit";
import { getHistory } from "./operation";


const initialStateHistory = {
  history: [],
  isLoading: false,
};

const historySlice = createSlice({
  name: "history",
  initialState: initialStateHistory,
  extraReducers: (builder) =>
    builder
      .addCase(getHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.history = action.payload;
      })
      .addCase(getHistory.rejected, (state) => {
        state.isLoading = false;
      }),
});

export const historyReducer = historySlice.reducer;
