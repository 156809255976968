import { useAuth } from "../../hooks/useAuth";
import {
  RightNavContainer,
  RightNavList,
  RightNavItem,
  LinkNav,
} from "./RightNav.styled";

const RightNav = () => {
  const { isLoggedIn, user } = useAuth();
  const isAllUserData = user.phone && user.birthday;
  return (
    <RightNavContainer isLoggedIn={isLoggedIn} isAllUserData={isAllUserData}>
      <RightNavList>
        <RightNavItem>
          <LinkNav to="/about">Про мене</LinkNav>
        </RightNavItem>
        <RightNavItem>
          <LinkNav to="/price">Ціни</LinkNav>
        </RightNavItem>
        <RightNavItem>
          <LinkNav to="/contacts">Контакти</LinkNav>
        </RightNavItem>
      </RightNavList>
    </RightNavContainer>
  );
};

export default RightNav;
