import cashIcon from "../images/icon-cabinet/cash.svg";
import cardIcon from "../images/icon-cabinet/card.svg";
import nonePayIcon from "../images/icon-cabinet/nonePay.svg";

const cash = (
  <img
    src={cashIcon}
    style={{ width: "24px", paddingBottom: "7px" }}
    alt="card"
  />
);
const card = (
  <img
    src={cardIcon}
    style={{ width: "24px", paddingBottom: "7px" }}
    alt="card"
  />
);
const nonePay = (
  <img
    src={nonePayIcon}
    style={{ width: "24px", paddingBottom: "7px" }}
    alt="card"
  />
);

export const selectType = [
  { value: "online", label: "Онлайн" },
  { value: "offline", label: "Офлайн" },
];

export const selectPayType = [
  {
    value: "cash",
    label: cash,
  },
  { value: "card", label: card },
  { value: "none", label: nonePay },
];
