import React from "react";
import {
  BoxAddress,
  BoxAddressUl,
  BoxMessengers,
  MessengersUl,
  PhoneIcon,
  StyledAddress,
  StyledContacts,
  TelegramIcon,
  UlAddress,
  ViberIcon,
} from "./Address.styled";

const Address = () => {
  return (
    <>
      <BoxAddress>
        Адреса:
        <address>
          <BoxAddressUl>
            <UlAddress>
              <li>
                <StyledAddress
                  href="https://maps.app.goo.gl/QZSw74NuHoxcU45KA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  м. Дніпро, вул. Сімферопольська 2-К
                </StyledAddress>
              </li>
              <li>
                <StyledContacts href="tel:+380671002328">
                  +38 067 100 23 28
                </StyledContacts>
              </li>
              <li>
                <StyledContacts href="mailto:dieta.cvilenko@gmail.com">
                  dieta.cvilenko@gmail.com
                </StyledContacts>
              </li>
            </UlAddress>
          </BoxAddressUl>
          <BoxMessengers>
            <MessengersUl>
              <li>
                <a href={process.env.REACT_APP_VIBER_BOT_LINK}>
                  <ViberIcon />
                </a>
              </li>
              <li>
                <a href={process.env.REACT_APP_TELEGRAM_BOT_LINK}>
                  <TelegramIcon />
                </a>
              </li>
              <li>
                <a href="tel:+380671002328">
                  <PhoneIcon />
                </a>
              </li>
            </MessengersUl>
          </BoxMessengers>
        </address>
      </BoxAddress>
    </>
  );
};
export default Address;
