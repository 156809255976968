export const selectDates = (state) => state.dates.dates;

export const selectDisabled = (state) =>
  state.dates.dates.reduce(
    (r, o) => {
      if (o.dayType === "offline" || o.dayType === "weekend") {
        r.onlineData.push(o);
      }
      if (o.dayType === "online" || o.dayType === "weekend") {
        r.offlineData.push(o);
      }
      return r;
    },
    { onlineData: [], offlineData: [] }
  );

export const selectLastDate = (state) =>
  state.dates.dates[state.dates.dates.length - 1].day;
