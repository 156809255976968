export const styledRegistrationBirthday = {
  backgroundColor: "#FBFBFB",
  color: "#404731",
  borderRadius: "20px",

  "& .MuiInputBase-input": {
    padding: "10.5px 16px ",
    cursor: "pointer",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    // border: ${(props) => (props.error ? "1px solid #EA4335" : "1px solid #647F29")},
    borderRadius: "20px",
    border: "1px solid #647F29",
  },
  "& .notranslate": {
    color: "#404731",
  },
};
export const styledRegistrationBirthdayError = {
  backgroundColor: "#FBFBFB",
  color: "#EA4335",
  borderRadius: "20px",
  "& .MuiInputBase-input": {
    padding: "10.5px 16px ",
    cursor: "pointer",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    // border: ${(props) => (props.error ? "1px solid #EA4335" : "1px solid #647F29")},
    borderRadius: "20px",
    border: "1px solid #EA4335",
    outline: "none",
  },
  "& .notranslate": {
    color: "#EA4335",
    // "&:focus": {     // не відпрацьовує
    //   color: "#EA4335",
    // },
  },
};

export const styledPersonalBirthday = {
  backgroundColor: "transparent",

  "& .MuiInputBase-input": {
    fontFamily: "'Barlow',sans-serif",
    padding: "6px 22px 10px 2px ",
    cursor: "pointer",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    borderRadius: 0,
    borderBottom: "1px solid #FBB336",
    "&:hover": {
      borderColor: "inherit",
      outline: "none",
    },
  },
};

export const styledPersonalBirthdayError = {
  backgroundColor: "transparent",

  "& .MuiInputBase-input": {
    fontFamily: "'Barlow',sans-serif",
    padding: "6px 22px 10px 2px ",
    cursor: "pointer",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    borderRadius: 0,
    borderBottom: "1px solid #FBB336",
    "&:hover": {
      borderColor: "inherit",
      outline: "none",
    },
  },
};
