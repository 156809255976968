import {
  ModalContainer,
  Text,
  ModalButtons,
  ModalBtn,
  CloseIcon,
  CloseBtn,
} from "./ModalWindow.styled";

const ModalWindow = ({
  cancelName,
  confirmName,
  onConfirm,
  onCancel,
  onCloseModal,
  text,
}) => {
  return (
    <>
      <ModalContainer>
        <Text>
          Ви дійсно <br /> {text}
        </Text>
        <ModalButtons>
          <ModalBtn
            style={{ backgroundColor: "#FBB336", color: "#ffffff" }}
            type="button"
            onClick={onCancel}
          >
            {cancelName}
          </ModalBtn>
          <ModalBtn type="button" onClick={onConfirm}>
            {confirmName}
          </ModalBtn>
        </ModalButtons>
        <CloseBtn type="button" onClick={onCloseModal}>
          <CloseIcon />
        </CloseBtn>
      </ModalContainer>
    </>
  );
};

export default ModalWindow;
