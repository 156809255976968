import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import MuiCalendar from "../MuiCalendar/MuiCalendar";
import TimePicker from "components/TimePicker/TimePicker";
import Buttons from "components/Buttons/Buttons";
import Loader from "components/Loader/Loader";

import { CalendarBox } from "./OnlineCalendar.styled";

import dayjs from "dayjs";

import { useRadioBtn } from "context/radioBtnContext";
import { useAppointmentsDispatch } from "hooks/useAppointmentsDispatch";

import {
  addAppointment,
  appointmentUpdate,
  getAppointments,
  setIsNewAppointmentToFalse,
} from "redux/appointments/operation";
import { selectAppointmentsError } from "redux/appointments/selector";
import { selectIsNewAppointment } from "redux/appointments/selector";

const OnlineCalendar = () => {
  const navigate = useNavigate();
  const { result, diagnosisRadio } = useOutletContext();

  const dispatch = useDispatch();
  const dispatchAddAppointment = useAppointmentsDispatch();

  const errorAppointments = useSelector(selectAppointmentsError);
  const isNewAppointment = useSelector(selectIsNewAppointment);

  const {
    dayValue,
    setDayValue,
    appointmentRadioChoice,
    setAppointmentRadioChoice,
    setAppointmentType,
    radioChoice,
    setIdAppointment,
    idAppointment,
    locationFirstApp,
    setLocationFirstApp,
  } = useRadioBtn();

  useEffect(() => {
    setAppointmentRadioChoice("");
    setAppointmentType("online");
    dispatch(getAppointments());
  }, [dispatch, setAppointmentRadioChoice, setAppointmentType, dayValue]);

  useEffect(() => {
    if (errorAppointments) {
      dispatch(getAppointments());
    }
  }, [dispatch, errorAppointments]);

  useEffect(() => {
    if (isNewAppointment) {
      dispatch(setIsNewAppointmentToFalse());
      setIdAppointment("");

      navigate("/success", { replace: true });
    }
  }, [isNewAppointment, navigate, dispatch, setIdAppointment]);

  const disabled = result.onlineData.map((d) => d.day);
  const selectDay = result.offlineData.find(
    (d) => d.day === dayjs(dayValue).format("MM-DD-YYYY")
  );

  const submitFunction = () => {
    if (radioChoice === "primary" && !locationFirstApp && !idAppointment) {
      navigate("/reception", { replace: true });
    } else {
      setLocationFirstApp(false);
      if (idAppointment) {
        dispatchAddAppointment(appointmentUpdate);
      } else {
        dispatchAddAppointment(addAppointment);
      }
    }
  };

  return (
    <>
      {disabled.length === 0 ? (
        <Loader isSmall={false} />
      ) : (
        <>
          {/* {radioChoice === "secondary" || radioChoice === "secondaryLong" ? (
            <NoList>
              Онлайн консультація з діагностикою не можлива, для запису
              перейдіть на календар Офлайн.
            </NoList>
          ) : ( */}
          <>
            <CalendarBox>
              <MuiCalendar
                disabled={disabled}
                value={dayValue}
                onSetValue={setDayValue}
                highlightedDates={[]}
              />

              <TimePicker
                selectDay={selectDay}
                value={dayValue}
                setRadioChoice={setAppointmentRadioChoice}
                appType="online"
                diagnosisRadio={diagnosisRadio}
                radioChoice={radioChoice}
              />
            </CalendarBox>
            <Buttons
              type="button"
              title={
                radioChoice === "primary" && !locationFirstApp
                  ? // from !== "cabinet"
                    "Далі"
                  : "Записатися"
              }
              disabled={!appointmentRadioChoice}
              handleFunction={submitFunction}
              styled={{ marginBottom: `92px`, marginTop: `48px` }}
            />
          </>
          {/* )} */}
        </>
      )}
    </>
  );
};

export default OnlineCalendar;
