import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { ReactComponent as GrommetMoney } from "../../images/grommet-money.svg";
import { ReactComponent as Right } from "../../images/right.svg";
import { ReactComponent as Down } from "../../images/chevron-down.svg";
import { ReactComponent as Money } from "../../images/money.svg";
import { ReactComponent as Card } from "../../images/creditcard.svg";
import { ReactComponent as None } from "../../images/error.svg";

export const ContainerBalance = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  width: 100%;
  max-width: 327px;

  margin-top: 96px;
  margin-left: auto;
  margin-right: auto;

  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  line-height: 20px;
  color: ${(p) => p.theme.palette.primary.main};

  @media ${(p) => p.theme.device.tablet} {
    max-width: 414px;
    margin-top: 128px;
  }
  @media ${(p) => p.theme.device.desktop} {
    max-width: 522px;
  }
`;

export const Text = styled.p`
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: ${(p) => p.theme.fontSizes[4]};
  line-height: 24px;
  text-align: center;
  color: ${(p) => p.theme.palette.primary.main};
  background-color: ${(p) => p.theme.palette.primary.secondaryText};
  border-radius: 24px;
  box-shadow: 0px 3px 12px 0px rgba(100, 127, 41, 0.15);
  border: none;
  padding: 8px;

  @media ${(p) => p.theme.device.mobile} {
    font-size: ${(p) => p.theme.fontSizes[6]};
    line-height: 1.1;
  }
`;

export const IconGrommetMoney = styled(GrommetMoney)`
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: ${(p) => p.theme.palette.primary.main};
  pointer-events: none;
`;

export const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 327px;

  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;

  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  line-height: 24px;
  color: ${(p) => p.theme.palette.primary.text};

  @media ${(p) => p.theme.device.tablet} {
    max-width: 414px;
    margin-top: 40px;
  }

  @media ${(p) => p.theme.device.desktop} {
    max-width: 522px;
  }
`;

export const InputChekContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 24px;
  text-align: start;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:has(input[type="checkbox"]) {
    border-bottom: 0.5px solid #fbb336;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;

  text-align: start;
  &:last-of-type {
    margin-bottom: 0;
  }

  &:has(input[type="checkbox"]) {
    border-bottom: 0.5px solid #fbb336;
  }
`;

export const InputContainerSpan = styled.span`
  position: absolute;
  top: 10px;
  left: 7%;
`;

export const UserNick = styled.p`
  padding: 10px 22px 10px 0;
  color: ${(p) => p.theme.palette.primary.text};
  font-family: ${(p) => p.theme.fonts.Barlow};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: ${(p) => p.theme.fontSizes[4]};
  line-height: 1.15;
  border-bottom: 0.5px solid #fbb336;
  width: 100%;
`;

export const Select = styled.select`
  padding: 10px 22px 10px 0;
  width: 100%;
  display: block;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
  font-size: 1rem;
  border-bottom: 0.5px solid #fbb336;
`;

export const Option = styled.option`
  display: block;
  width: 100%;
`;

export const Button = styled.button`
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 24px;

  width: 100%;
  margin-top: 56px;
  margin-bottom: 24px;
  padding: 12px;
  color: ${(p) => p.theme.palette.primary.secondaryText};
  background: ${(p) => p.theme.palette.primary.btn};
  border-radius: 24px;
  &:disabled {
    background: #a7a8a6;
  }
`;

export const ButtonNone = styled.button`
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 24px;

  width: 100%;
  margin-bottom: 78px;
  padding: 12px;
  color: ${(p) => p.theme.palette.primary.secondaryText};
  background: ${(p) => p.theme.palette.primary.error};
  border-radius: 24px;
`;

export const ButtonClose = styled(Link)`
  position: absolute;
  top: 16px;
  left: 24px;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: transparent;

  @media ${(p) => p.theme.device.tablet} {
    left: 32px;
  }
  @media ${(p) => p.theme.device.desktop} {
    left: 80px;
  }
`;

export const IconRight = styled(Right)`
  cursor: pointer;
  display: block;
  width: 10px;
  height: 20px;
  color: #647f29;
`;

export const IconMoney = styled(Money)`
  cursor: pointer;
  display: block;
  width: 24px;
  height: 24px;
`;

export const IconCard = styled(Card)`
  cursor: pointer;
  display: block;
  width: 24px;
  height: 24px;
`;

export const IconNone = styled(None)`
  cursor: pointer;
  display: block;
  width: 24px;
  height: 24px;
`;

export const IconDown = styled(Down)`
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #647f29;
  position: absolute;
  top: 15px;
  right: 0px;
  pointer-events: none;
`;

export const ChekLable = styled.label`
  cursor: pointer;
  background-color: transparent;
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 24px;
  color: ${(p) => p.theme.palette.primary.text};

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 1;
    right: 0;
    width: 24px;
    height: 24px;
    border: 2px solid #647f29;
    border-radius: 4px;
  }

  &:after {
    content: "\\2713";
    display: block;
    position: absolute;
    opacity: 0;
    bottom: 9px;
    right: 4px;
    /* z-index: 1; */
    font-size: ${(p) => p.theme.fontSizes[6]};
    font-weight: ${(p) => p.theme.fontWeights.semibold};
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

export const ChekInput = styled.input`
  display: inline-block;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;

  &:checked + label:before {
    background-color: transparent;
    border-color: ${(p) => p.theme.palette.primary.details};
  }

  &:checked + label:after {
    opacity: 1;
  }
`;
