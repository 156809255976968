import React from "react";
import {
  ModalContainer,
  ModalText,
  IconClick,
  Box,
} from "./ModalWindow.styled";

const ModalFalseAdmin = ({ onClose }) => {
  return (
    <>
      <ModalContainer onClick={onClose}>
        <Box>
          <IconClick />
          <ModalText>Прийом відмінено!</ModalText>
        </Box>
      </ModalContainer>
    </>
  );
};

export default ModalFalseAdmin;
