import styled from "@emotion/styled";
import { ReactComponent as Users } from "../../../images/users.svg";
import { ReactComponent as Telephone } from "../../../images/telephone.svg";

export const Title = styled.h2`
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[5]};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  line-height: 24px;
  color: ${(p) => p.theme.palette.primary.main};
  margin-bottom: 10px;

  @media ${(p) => p.theme.device.tablet} {
    margin-bottom: 16px;
  }
`;

export const BoxSchedule = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const BoxUl = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.semibold};
  line-height: 24px;
  color: ${(p) => p.theme.palette.primary.main};

  @media ${(p) => p.theme.device.tablet} {
    flex-direction: row;
    justify-content: flex-start;
    gap: 54px;
  }
`;

export const UlList = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 143px;
`;

export const UlListOff = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  @media ${(p) => p.theme.device.mobile} {
    width: 98px;
  }
`;

export const Pdays = styled.p`
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  line-height: 24px;
  color: ${(p) => p.theme.palette.primary.main};
`;

export const IconUsers = styled(Users)`
  position: absolute;
  left: 75px;
  width: 20px;
  height: 20px;
  color: ${(p) => p.theme.palette.primary.main};
`;

export const IconTelephone = styled(Telephone)`
  position: absolute;
  left: 68px;
  width: 20px;
  height: 20px;
  color: ${(p) => p.theme.palette.primary.main};
`;
