import styled from "@emotion/styled";
import check from "../../images/checkBold.png";

export const RadioContainer = styled.div`
  width: calc(100vw - 48px);
  height: 200px;

  background: #ffffff;

  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;

  background: #fbfbfb;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  overflow-y: scroll;
  scrollbar-color: #85b53a;
  scroll-margin-left: 20px;

  @media (min-width: 375px) {
    width: 327px;
  }
  @media (min-width: 768px) {
    width: 414px;
    background: #ffffff;
  }
  @media (min-width: 1440px) {
    height: 360px;
    margin-top: 0;
    border-radius: 24px 8px 8px 24px;
  }
  &::-webkit-scrollbar {
    margin-right: 5px;

    width: 8px;
    height: 25px;

    @media (min-width: 1440px) {
      width: 10px;
      height: 332px;
      padding: 0px 1px;
    }
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: -1px 0px 0px 0px #f0f0f0 inset,
      1px 0px 0px 0px #e8e8e8 inset;
    border-radius: 0 24px 24px 0;
    background: #fafafa;
    height: 25px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #85b53a;
  }
`;

export const TimeLabel = styled.label`
  position: relative;
  cursor: pointer;
`;

export const TimeInput = styled.input`
  display: none;
  -webkit-appearance: none;
  appearance: none;

  &:checked + span {
    background: #d3e5b8;
  }
  &:checked + span:after {
    background: #ffffff;

    content: "";
    background: url(${check}) no-repeat center;
    background-size: 10px;

    width: 16px;
    height: 16px;
    margin-left: auto;
    /* 
    transform: translateY(-50%); */
    display: block;

    padding-left: 1px;

    font-size: ${(p) => p.theme.fontSizes[3]};
    font-weight: ${(p) => p.theme.fontWeights.semibold};
    color: ${(p) => p.theme.palette.primary.main};
  }

  &:disabled + span {
    color: #d3d3d3;
  }
  &:disabled + span:after {
    border: 1px solid #d3d3d3;
  }

  /* content: "\\2713";
    display: block;
    padding-left: 1px;

    font-size: ${(p) => p.theme.fontSizes[3]};
    font-weight: ${(p) => p.theme.fontWeights.semibold};
    fill: #d3d3d3;
  }  */
  /* &:disabled + span:before {
  } */
`;
export const TimeSpan = styled.span`
  display: flex;
  padding: 12px 16px;
  border-bottom: 0.5px solid #e4e9d8;

  font-weight: 600;
  font-size: 16px;
  line-height: 1;

  color: #647f29;

  &::after {
    content: "";
    box-sizing: border-box;
    display: inline-block;

    width: 16px;
    height: 16px;

    margin-left: auto;

    border-radius: 50%;
    border: 1px solid #fbb336;
  }
`;
export const NoList = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-family: Manrope;
  margin-top: 24px;
  padding: 12px 16px;
  text-align: center;
  line-height: 1.6;

  border-radius: 24px;
  color: #647f29;
  /* background-color: #ffffff; */
  background: #fbfbfb;
  box-shadow: 0px 4px 11px 0px rgba(100, 127, 41, 0.15),
    4px 0px 11px 0px rgba(100, 127, 41, 0.15);

  @media (min-width: 1440px) {
    width: 414px;
  }
`;

// const dot = (color = "transparent") => ({
//   alignItems: "center",
//   display: "flex",

//   ":before": {
//     backgroundColor: color,
//     borderRadius: 10,
//     content: '" "',
//     display: "block",
//     marginRight: 8,
//     height: 10,
//     width: 10,
//   },
// });

// const colourStyles: StylesConfig<ColourOption> = {
//   control: (styles) => ({ ...styles, backgroundColor: "white" }),
//   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
//     const color = chroma(data.color);
//     return {
//       ...styles,
//       backgroundColor: isDisabled
//         ? undefined
//         : isSelected
//         ? data.color
//         : isFocused
//         ? color.alpha(0.1).css()
//         : undefined,
//       color: isDisabled
//         ? "#ccc"
//         : isSelected
//         ? chroma.contrast(color, "white") > 2
//           ? "white"
//           : "black"
//         : data.color,
//       cursor: isDisabled ? "not-allowed" : "default",

//       ":active": {
//         ...styles[":active"],
//         backgroundColor: !isDisabled
//           ? isSelected
//             ? data.color
//             : color.alpha(0.3).css()
//           : undefined,
//       },
//     };
//   },
//   input: (styles) => ({ ...styles, ...dot() }),
//   placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
//   singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
// };
