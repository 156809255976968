import styled from "@emotion/styled";

export const Box = styled.header`
  position: relative;
  height: 88px;
  padding: 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.theme.palette.primary.bg};
  border-bottom: 1px solid #e4e9d8;

  @media ${(p) => p.theme.device.tablet} {
    height: 112px;
    padding: 36px 32px;
  }

  @media ${(p) => p.theme.device.desktop} {
    height: 112px;
    padding: 32px 80px;
  }
`;

export const LogoImg = styled.img`
  top: 24px;
  left: 24px;
  border-radius: 50px;
  position: absolute;
  z-index: 3;

  @media ${(p) => p.theme.device.tablet} {
    top: 36px;
    left: 32px;
  }

  @media ${(p) => p.theme.device.desktop} {
    top: 36px;
    left: 80px;
  }
`;
