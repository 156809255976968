import { createSlice } from "@reduxjs/toolkit";
import {
  logIn,
  logOut,
  refreshCurrentUser,
  register,
  userUpdate,
  userChangePassword,
  userUpdateByAdmin,
  totalLogOut,
} from "./operation";

const initialStateAuth = {
  user: {
    nickName: null,
    birthday: null,
    phone: null,
    email: null,
    notifications: null,
    _id: null,
    registerType: null,
    role: null,
    deposit: null,
    balance: null,
    telegram: null,
    viber: null,
  },
  token: null,
  isLoggedIn: false,
  isRefreshing: false,
};

const handleExitFulfilled = (state, action) => {
  state.user._id = action.payload.user._id;
  state.user.nickName = action.payload.user.nickName;
  state.user.birthday = action.payload.user.birthday;
  state.user.phone = action.payload.user.phone;
  state.user.email = action.payload.user.email;
  state.user.registerType = action.payload.user.registerType;
  state.user.role = action.payload.user.role;
  state.user.notifications = action.payload.user.notifications;
  state.user.deposit = action.payload.user.deposit;
  state.user.balance = action.payload.user.balance;
  state.user.telegram = action.payload.user.telegram;
  state.user.viber = action.payload.user.viber;

  state.token = action.payload.user.token;
  state.isLoggedIn = true;
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialStateAuth,
  reducers: {
    totalLogOut: (state) => {
      // console.log("totalLogOut!!!!");
      state.isLoggedIn = false;
      state.token = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(register.fulfilled, handleExitFulfilled)
      .addCase(logIn.fulfilled, handleExitFulfilled)
      .addCase(logOut.fulfilled, (state) => {
        state.user.nickName = null;
        state.user.birthday = null;
        state.user.phone = null;
        state.user.email = null;
        state.user.notifications = null;
        state.token = null;
        state.user.registerType = null;
        state.user.role = null;
        state.user.deposit = null;
        state.user.balance = null;
        state.user._id = null;
        state.user.telegram = null;
        state.user.viber = null;

        state.isLoggedIn = false;
      })
      .addCase(refreshCurrentUser.pending, (state) => {
        state.isRefreshing = true;
      })
      .addCase(refreshCurrentUser.fulfilled, (state, action) => {
        state.user.nickName = action.payload.currentUser.nickName;
        state.user.birthday = action.payload.currentUser.birthday;
        state.user.phone = action.payload.currentUser.phone;
        state.user.registerType = action.payload.currentUser.registerType;
        state.user.role = action.payload.currentUser.role;
        state.user.email = action.payload.currentUser.email;
        state.user.deposit = action.payload.currentUser.deposit;
        state.user.balance = action.payload.currentUser.balance;
        state.user.telegram = action.payload.currentUser.telegram;
        state.user.viber = action.payload.currentUser.viber;

        // state.user.registerType = action.payload.user.registerType;
        state.user.notifications = action.payload.currentUser.notifications;
        state.user._id = action.payload.currentUser._id;
        // fdsa del
        state.token = action.payload.currentUser.token; // TODO: changed & need more changes
        state.isLoggedIn = true;
        state.isRefreshing = false;
      })
      .addCase(refreshCurrentUser.rejected, (state) => {
        state.isRefreshing = false;
        state.isLoggedIn = false;
      })
      .addCase(userUpdate.fulfilled, (state, action) => {
        state.user.nickName = action.payload.user.nickName;
        state.user.birthday = action.payload.user.birthday;
        state.user.phone = action.payload.user.phone;
        state.user.email = action.payload.user.email;
        state.user.deposit = action.payload.user.deposit;
        state.user.balance = action.payload.user.balance;
        state.user.notifications = action.payload.user.notifications;
      })
      .addCase(userUpdateByAdmin.fulfilled, (state, action) => {
        state.user.deposit = action.payload.user.deposit;
        state.user.balance = action.payload.user.balance;
      })
      .addCase(userChangePassword.fulfilled, (state, action) => {
        state.token = action.payload.user.token;
      })
      .addCase(totalLogOut, (state, action) => {
        state.isLoggedIn = false;
        state.token = null;
      }),
});

export const authReducer = authSlice.reducer;
