import styled from "@emotion/styled";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { ReactComponent as Pensil } from "../../images/pensil.svg";
import check from "../../images/check.png";

export const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 327px;

  margin-left: auto;
  margin-right: auto;
  padding-bottom: 66px;

  @media ${(p) => p.theme.device.tablet} {
    max-width: 414px;
    padding-bottom: 250px;
  }
  @media ${(p) => p.theme.device.desktop} {
    max-width: 522px;
    padding-bottom: 192px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 44px;
  margin-bottom: 24px;

  /* display: inline-flex;
  justify-content: space-between;
  align-items: center; */

  text-align: start;
  &:last-of-type {
    margin-bottom: 0;
  }

  &:has(input[type="checkbox"]) {
    border-bottom: 0.5px solid #fbb336;
  }
`;

export const Input = styled.input`
  width: 100%;
  display: block;
  padding: 10px 0 10px 0;

  cursor: pointer;
  background-color: inherit;
  color: ${(p) => p.theme.palette.primary.text};
  font-family: ${(p) => p.theme.fonts.Barlow};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: ${(p) => p.theme.fontSizes[4]};
  line-height: 1.375;
  border: none;
  border-bottom: 0.5px solid #fbb336;
  padding-right: 24px;
  overflow-y: scroll;

  &:focus,
  :hover {
    outline: none;
  }

  &:disabled {
    width: 100%;
    padding: 10px 20px 10px 0;

    cursor: auto;
  }
`;

export const TextField = styled.p`
  position: absolute;
  top: 52px;
  left: 4px;

  font-size: 12px;
  line-height: 14px;
`;

export const IconPensil = styled(Pensil)`
  position: absolute;
  top: 10px;
  right: 0;
  width: 20px;
  height: 20px;

  z-index: -1;
`;
export const ButtonPassword = styled.button`
  position: relative;
  display: block;
  width: 100%;
  height: 44px;

  text-align: left;
  /* margin-top: 24px; */
  padding: 10px 22px 10px 2px;

  background-color: inherit;
  color: ${(p) => p.theme.palette.primary.text};
  font-family: ${(p) => p.theme.fonts.Barlow};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: ${(p) => p.theme.fontSizes[4]};
  line-height: 1.15;
  border: none;
  border-bottom: 0.5px solid #fbb336;

  cursor: pointer;

  &:focus,
  :hover {
    /* border-bottom: 3px solid #fbb336; */
    outline: none;
  }
`;

export const BoxRadio = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 62px;
  margin-bottom: 48px;
`;

export const RadioLabel = styled.span`
  display: flex;
  align-items: center;
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 1.25;
  padding-left: 4px;
  color: ${(p) => p.theme.palette.primary.main};

  &:before {
    content: "";
    box-sizing: border-box;
    display: block;
    position: absolute;
    /* top: 0; */ // так іконка стає посередині
    left: 0;
    width: 24px;
    height: 24px;
    border: ${(p) => p.theme.border[7]};
    border-radius: 4px;
  }
`;

export const Text = styled.p`
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 1;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 16px;
  color: ${(p) => p.theme.palette.primary.main};
`;

export const PhoneIn = styled(PhoneInput)`
  width: 100%;
  height: 44px;

  font-family: ${(p) => p.theme.fonts.Barlow};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  font-size: ${(p) => p.theme.fontSizes[4]};
  line-height: 1.15;

  color: ${(p) => p.theme.palette.primary.text};
  border: none;
  border-bottom: 0.5px solid #fbb336;
  background-color: inherit;

  &:focus,
  :hover {
    outline: none;
  }

  & .form-control {
    width: calc(100% - 24px);
    height: 100%;

    font-family: ${(p) => p.theme.fonts.Barlow};
    font-size: ${(p) => p.theme.fontSizes[4]};
    border: none;
    background: inherit;

    &.open {
      border: none;
    }
  }
  & .selected-flag {
    background-color: inherit;
    border: none;

    &:focus {
      background-color: inherit;
      border: none;
    }
  }
  & .flag-dropdown.open .selected-flag {
    border: none;
    background: inherit;
  }

  & .react-tel-input,
  & .flag-dropdown {
    /* border-radius: 20px 0 0 20px; */
    border: none;
    background: inherit;
    &.open {
      /* border-radius: 20px 0 0 20px; */
      border: none;
      background: inherit;
    }
  }
`;

export const RadioContainer = styled.label`
  position: relative;
  padding-left: 40px;
`;

export const RadioInput = styled.input`
  display: none;

  &:checked + span:after {
    content: "";
    background: url(${check}) no-repeat center;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0px;
    width: 25px;
    height: 25px;

    transform: translateY(-50%);
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`;
