import { useState } from "react";
import {
  OpenLinksButton,
  NavBarExtendedContainer,
  NavEx,
  NavExBox,
  LinkNav,
  IconBurger,
  IconClose,
} from "./BurgerMenu.styled";
import { useAuth } from "hooks/useAuth";

const BurgerMenu = () => {
  const [extendNavbar, setExtendNavbar] = useState(false);
  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const isAllUserData = user.phone && user.birthday;

  const onCloseBurger = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const handleBurger = () => {
    setExtendNavbar((curr) => !curr);
    if (!extendNavbar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    onCloseBurger();
  };

  const handleCloseToLinkModal = () => {
    setExtendNavbar(false);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <OpenLinksButton type="button" onClick={handleBurger}>
        {!extendNavbar ? (
          <>
            <IconBurger />
          </>
        ) : (
          <>
            <IconClose onClick={onCloseBurger} />
          </>
        )}
      </OpenLinksButton>

      {extendNavbar && (
        <>
          <NavBarExtendedContainer>
            <NavExBox>
              <NavEx>
                <LinkNav to="/about" onClick={handleCloseToLinkModal}>
                  Про мене
                </LinkNav>
                <LinkNav to="/price" onClick={handleCloseToLinkModal}>
                  Ціни
                </LinkNav>
                <LinkNav to="/contacts" onClick={handleCloseToLinkModal}>
                  Контакти
                </LinkNav>
                {isAllUserData && (
                  <LinkNav to="/cabinet" onClick={handleCloseToLinkModal}>
                    Кабінет
                  </LinkNav>
                )}
              </NavEx>
            </NavExBox>
          </NavBarExtendedContainer>
        </>
      )}
    </>
  );
};

export default BurgerMenu;
