export function timeToMin(str) {
  const [g, m] = str.split(":");

  return g * 60 + Number(m);
}

export function minToTime(numbr) {
  let min = numbr % 60;
  let h = Math.floor(numbr / 60);

  if (min < 10) {
    min = "0" + min;
  }
  if (h < 10) {
    h = "0" + h;
  }

  return [h, min].join(":");
}
