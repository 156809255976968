import { object, string } from "yup";

import { validatePhoneNumber } from "helpers/validatePhoneNumber";

const schema = object().shape({
  phone: string()
    .test("phone", "Невірний номер телефону", function (value) {
      const result = validatePhoneNumber(value); // Викликаємо вашу функцію для перевірки

      // Якщо результат перевірки є рядком, то це повідомлення про помилку
      if (typeof result === "string") {
        return this.createError({ path: "phone", message: result });
      }
      return true;
    })
    .required("Номер телефону обов'язковий"),

  password: string()
    .min(6, "Введіть мінімум 6 символів ")
    .max(32, "Пароль має бути до 32 символів")
    .required("Поле обов'язкове")
    .trim(),
});

export default schema;
