export function validatePhoneNumber(phoneNumber) {
  switch (true) {
    case /^380/.test(phoneNumber):
      if (phoneNumber.length < 12) {
        return "Введіть повністью номер";
      }
      break;
    case /^49/.test(phoneNumber):
      if (phoneNumber.length < 12) {
        return "Введіть повністью номер";
      }
      break;

    case /^44/.test(phoneNumber):
      if (phoneNumber.length < 11) {
        return "Введіть повністью номер";
      }
      break;
    case /^48/.test(phoneNumber):
      if (phoneNumber.length < 11) {
        return "Введіть повністью номер";
      }
      break;
    case /^31/.test(phoneNumber):
      if (phoneNumber.length < 11) {
        return "Введіть повністью номер";
      }
      break;
    case /^1/.test(phoneNumber):
      if (phoneNumber.length < 11) {
        return "Введіть повністью номер";
      }
      break;

    // Додайте інші країни і їх правила тут
    default:
      break;
    // return "Введіть повністью номер";
  }
}
