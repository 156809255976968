import { useNavigate, useOutletContext } from "react-router-dom";
import MuiCalendar from "../MuiCalendar/MuiCalendar";
import TimePicker from "components/TimePicker/TimePicker";

import dayjs from "dayjs";

import Buttons from "components/Buttons/Buttons";
import { useRadioBtn } from "context/radioBtnContext";
import { useEffect } from "react";
import { useAppointmentsDispatch } from "hooks/useAppointmentsDispatch";
import Loader from "components/Loader/Loader";
import { selectAppointmentsError } from "redux/appointments/selector";
import { useDispatch, useSelector } from "react-redux";
import {
  addAppointment,
  appointmentUpdate,
  getAppointments,
  setIsNewAppointmentToFalse,
} from "redux/appointments/operation";
import { selectIsNewAppointment } from "redux/appointments/selector";
import DiagnosisRadioBtn from "components/DiagnosisRadioBtn/DiagnosisRadioBtn";
import { CalendarBox } from "../OnlineCalendar/OnlineCalendar.styled";

const OfflineCalendar = () => {
  const navigate = useNavigate();
  const { result, diagnosisRadio } = useOutletContext();

  const dispatch = useDispatch();
  const dispatchAddAppointment = useAppointmentsDispatch();

  const errorAppointments = useSelector(selectAppointmentsError);
  const isNewAppointment = useSelector(selectIsNewAppointment);

  const {
    dayValue,
    setDayValue,
    appointmentRadioChoice,
    setAppointmentRadioChoice,
    setAppointmentType,
    radioChoice,
    setRadioChoice,
    setIdAppointment,
    idAppointment,
    locationFirstApp,
    setLocationFirstApp,
  } = useRadioBtn();

  useEffect(() => {
    setAppointmentRadioChoice("");
    setAppointmentType("offline");
    dispatch(getAppointments());
  }, [dispatch, setAppointmentRadioChoice, setAppointmentType, dayValue]);

  useEffect(() => {
    if (errorAppointments) {
      dispatch(getAppointments());
    }
  }, [dispatch, errorAppointments]);

  useEffect(() => {
    if (isNewAppointment) {
      dispatch(setIsNewAppointmentToFalse());
      setIdAppointment("");

      navigate("/success", { replace: true });
    }
  }, [isNewAppointment, navigate, dispatch, setIdAppointment]);

  const disabled = result.offlineData.map((d) => d.day);
  const selectDay = result.onlineData.find(
    (d) => d.day === dayjs(dayValue).format("MM-DD-YYYY")
  );

  const submitFunction = () => {
    if (radioChoice === "primary" && !idAppointment && !locationFirstApp) {
      navigate("/reception", { replace: true });
    } else {
      setLocationFirstApp(false);

      if (idAppointment) {
        dispatchAddAppointment(appointmentUpdate);
        setIdAppointment("");
      } else {
        dispatchAddAppointment(addAppointment);
      }
    }
  };

  return (
    <>
      {disabled.length === 0 ? (
        <Loader isSmall={false} />
      ) : (
        <>
          {(radioChoice === "secondaryLong" ||
            radioChoice === "secondaryShot") && (
            <DiagnosisRadioBtn onDiagnosisSet={setRadioChoice} />
          )}
          <CalendarBox>
            <MuiCalendar
              disabled={disabled}
              value={dayValue}
              onSetValue={setDayValue}
              highlightedDates={[]}
            />
            <TimePicker
              selectDay={selectDay}
              value={dayValue}
              setRadioChoice={setAppointmentRadioChoice}
              appType="offline"
              diagnosisRadio={diagnosisRadio}
              radioChoice={radioChoice}
            />
          </CalendarBox>
          <Buttons
            type="button"
            title={
              radioChoice === "primary" && !idAppointment
                ? // from !== "cabinet"
                  "Далі"
                : "Записатися"
            }
            disabled={!appointmentRadioChoice}
            handleFunction={submitFunction}
            styled={{ marginBottom: `92px`, marginTop: `48px` }}
          />
        </>
      )}
    </>
  );
};

export default OfflineCalendar;
