import { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";

import { logIn } from "redux/auth/operation";
import schema from "utils/schema/SigninSchema";
import {
  Box,
  Form,
  Label,
  Span,
  Input,
  InputContainer,
  TextHelper,
  PasswordToggleIcon,
  ButtonForgot,
  WrapperFormForgot,
  PhoneIn,
  IconGoogle,
} from "../AuthForm.styled";

import ModalResetPassword from "components/ModalWindow/ModalResetPassword";
import ModalBackdrop from "components/Modal/Modal";

import GoogleButton from "components/googleButton/GoogleButton";
import Buttons from "components/Buttons/Buttons";
import usePasswordToggle from "hooks/usePasswordToggle";

const SigninForm = () => {
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: (values, formikHelpers) => {
      dispatch(
        logIn({
          phone: "+" + values.phone,
          password: values.password,
        })
      );
      formikHelpers.resetForm();
    },
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = formik;

  return (
    <Box>
      <WrapperFormForgot>
        <Form onSubmit={handleSubmit} autoComplete="off">
          <InputContainer>
            <Label
              htmlFor="phone"
              style={errors.phone && touched[undefined] && { color: "#EA4335" }}
            >
              *Телефон
            </Label>
            <PhoneIn
              country={"ua"}
              id="phone"
              name="phone"
              type="tel"
              value={values.phone}
              onChange={(value) => setFieldValue("phone", value)}
              onBlur={handleBlur}
              autoComplete="off"
              placeholder="+380991234567"
              preferredCountries={["ua"]}
              excludeCountries={["ru"]}
              style={
                errors.phone && touched[undefined] && { borderColor: "#EA4335" }
              }
            />
            {errors.phone && touched[undefined] && (
              <TextHelper style={{ color: "#EA4335" }}>
                {errors.phone}
              </TextHelper>
            )}
          </InputContainer>

          <InputContainer>
            <Label
              htmlFor="password"
              style={
                errors.password && touched.password && { color: "#EA4335" }
              }
            >
              *Пароль
            </Label>
            <Input
              id="password"
              name="password"
              type={PasswordInputType}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              style={
                errors.password && touched.password
                  ? { borderColor: "#EA4335", paddingRight: "42px" }
                  : { paddingRight: "42px" }
              }
            />
            <PasswordToggleIcon>{ToggleIcon}</PasswordToggleIcon>
            {errors.password && touched.password && (
              <TextHelper style={{ color: "#EA4335" }}>
                {errors.password}
              </TextHelper>
            )}
          </InputContainer>

          <Buttons
            type="submit"
            disabled={!(formik.isValid && formik.dirty)}
            title="Увійти"
          />
          <Span>або</Span>
          <GoogleButton>Увійти з Google</GoogleButton>
          <IconGoogle />
        </Form>
        <ButtonForgot type="button" onClick={() => setShowModalPassword(true)}>
          Забули пароль?
        </ButtonForgot>

        {showModalPassword && (
          <ModalBackdrop onClose={() => setShowModalPassword(false)}>
            <ModalResetPassword
              onCloseModal={() => setShowModalPassword(false)}
            />
          </ModalBackdrop>
        )}
      </WrapperFormForgot>
    </Box>
  );
};
export default SigninForm;
