import Buttons from "components/Buttons/Buttons";
import ModalBackdrop from "components/Modal/Modal";
import ModalWindow from "components/ModalWindow/ModalWindow";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userDeleteByAdmin } from "redux/auth/operation";

const DeleteBtn = ({ userId, backLink }) => {
  const [delUser, setDelUser] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteUser = (userId) => {
    dispatch(userDeleteByAdmin(userId));
    setDelUser(false);
    navigate(backLink);
  };

  return (
    <>
      <Buttons
        title="Видалити пацієнта"
        type="submit"
        handleFunction={() => setDelUser(true)}
        styled={{
          marginBottom: `32px`,
          background: "#EA4335",
        }}
      />
      {delUser && (
        <ModalBackdrop onClose={() => setDelUser(false)}>
          <ModalWindow
            cancelName="Ні"
            confirmName="Так"
            onConfirm={() => deleteUser(userId)}
            onCancel={() => setDelUser(false)}
            onCloseModal={() => setDelUser(false)}
            // text={`хочете видалити пацієнта ${userName}?`}
            text={`хочете видалити пацієнта?`}
          />
        </ModalBackdrop>
      )}
    </>
  );
};

export default DeleteBtn;
