import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

export const RightNavContainer = styled.div`
  display: none;

  @media ${(p) => p.theme.device.desktop} {
    display: flex;
    margin-right: ${({ isLoggedIn, isAllUserData }) =>
      !isLoggedIn && !isAllUserData ? "159px" : "0px"};
  }
`;

export const RightNavList = styled.ul`
  display: flex;
  align-items: center;
  gap: 48px;
`;

export const RightNavItem = styled.li``;

export const LinkNav = styled(NavLink)`
  font-family: ${(p) => p.theme.fonts.Comfortaa};
  font-size: ${(p) => p.theme.fontSizes[6]};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  line-height: 40px;
  color: ${(p) => p.theme.palette.primary.main};
  background-color: transparent;
  padding: 10px 22px;
  border-radius: 20px;
  border: 1px solid transparent;

  &:hover,
  &:focus {
    border: 1px solid #85b53a;
    color: #404731;
  }
  &.active {
    color: ${(p) => p.theme.palette.primary.secondaryText};
    background-color: ${(p) => p.theme.palette.primary.btn};
  }
`;
