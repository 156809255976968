import dayjs from "dayjs";
import { minToTime, timeToMin } from "./changeInTime";

export function createTimeList(selectDay, appointDay, visitDuration, isAdmin) {
  if (!selectDay.appointmentsStart) {
    return null;
  }

  const arr = [];
  const start = timeToMin(selectDay.appointmentsStart);
  const end = timeToMin(selectDay.appointmentsEnd);
  const now = timeToMin(`${dayjs().hour()}:${dayjs().minute()}`);

  const tomorrowDay = dayjs().add(1, "day").format("MM-DD-YYYY");
  appointDay.sort((a, b) => a.startTime - b.startTime);
  if (isAdmin) {
    appointDay.forEach((d) => {
      if (d.startTime < start) {
        arr.push({
          value: `${minToTime(d.startTime)} - ${minToTime(d.endTime)}`,
          label: `${minToTime(d.startTime)} - ${minToTime(d.endTime)}`,
          isDisabled: true,
        });
      }
    });
  }

  if (start < 750) {
    for (let i = start; i <= 750 - visitDuration; i += 30) {
      const isAdminProof =
        (!isAdmin && now > 1200 && selectDay.day === tomorrowDay) ||
        (!isAdmin && selectDay.day === dayjs.utc().format("MM-DD-YYYY")) ||
        appointDay.length >= 13;
      if (isAdminProof) {
        const startI = minToTime(i);
        const endI = minToTime(i + visitDuration);
        arr.push({
          value: `${startI} - ${endI}`,
          label: `${startI} - ${endI}`,
          isDisabled: true,
        });
      } else {
        if (appointDay.length !== 0) {
          appointDay.forEach((d) => {
            if (
              arr.length &&
              d.startTime >
                timeToMin(arr[arr.length - 1].value.split(" - ")[0]) &&
              d.endTime < timeToMin(arr[arr.length - 1].value.split(" - ")[1])
            ) {
              arr.pop();
            }
            if (
              (i + visitDuration > d.startTime &&
                i + visitDuration <= d.endTime) ||
              (d.startTime > i && d.startTime < i + visitDuration)
            ) {
              i = d.startTime;
            }
            if (d.startTime === i) {
              arr.push({
                value: `${minToTime(d.startTime)} - ${minToTime(d.endTime)}`,
                label: `${minToTime(d.startTime)} - ${minToTime(d.endTime)}`,

                isDisabled: true,
              });
              i = d.endTime;
            }
          });
        }
        if (i <= 750 - visitDuration) {
          const startI = minToTime(i);
          const endI = minToTime(i + visitDuration);
          arr.push({
            value: `${startI} - ${endI}`,
            label: `${startI} - ${endI}`,
            isDisabled: false,
          });
        }
      }
    }
    arr.push({
      value: `12:30 - 13:00`,
      label: `12:30 - 13:00`,

      isDisabled: true,
    });
  }
  const secondStart = start < 750 ? 780 : start;
  for (let i = secondStart; i <= end - visitDuration; i += 30) {
    const isAdminProof =
      (!isAdmin && now > 1200 && selectDay.day === tomorrowDay) ||
      (!isAdmin && selectDay.day === dayjs.utc().format("MM-DD-YYYY")) ||
      appointDay.length >= 13;
    if (isAdminProof) {
      const startI = minToTime(i);
      const endI = minToTime(i + visitDuration);
      arr.push({
        value: `${startI} - ${endI}`,
        label: `${startI} - ${endI}`,
        isDisabled: true,
      });
    } else {
      if (appointDay.length !== 0) {
        appointDay.forEach((d) => {
          if (
            arr.length &&
            d.startTime >
              timeToMin(arr[arr.length - 1].value.split(" - ")[0]) &&
            d.endTime < timeToMin(arr[arr.length - 1].value.split(" - ")[1])
          ) {
            arr.pop();
          }
          if (
            (i + visitDuration > d.startTime &&
              i + visitDuration <= d.endTime) ||
            (d.startTime > i && d.startTime < i + visitDuration)
          ) {
            i = d.startTime;
          }
          if (d.startTime === i) {
            arr.push({
              value: `${minToTime(d.startTime)} - ${minToTime(d.endTime)}`,
              label: `${minToTime(d.startTime)} - ${minToTime(d.endTime)}`,

              isDisabled: true,
            });
            i = d.endTime;
          }
        });
      }

      if (i <= end - visitDuration) {
        const startI = minToTime(i);
        const endI = minToTime(i + visitDuration);
        arr.push({
          value: `${startI} - ${endI}`,
          label: `${startI} - ${endI}`,
          isDisabled: false,
        });
      }
    }
  }
  if (isAdmin) {
    appointDay.forEach((d) => {
      if (d.startTime >= end) {
        arr.push({
          value: `${minToTime(d.startTime)} - ${minToTime(d.endTime)}`,
          label: `${minToTime(d.startTime)} - ${minToTime(d.endTime)}`,
          isDisabled: true,
        });
      }
    });
  }

  return arr;
}
