import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getHistory = createAsyncThunk("history/get", async (userId, thunkAPI) => {
    try {
      const r = await axios.get(`/user/history-by-admin/${userId}`);
      return r.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  });