import styled from "@emotion/styled";

import { ReactComponent as Telegram } from "../../../images/contacts/telegram.svg";
import { ReactComponent as Viber } from "../../../images/contacts/viber.svg";
import { ReactComponent as Phone } from "../../../images/contacts/phone.svg";

export const BoxAddress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[5]};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  line-height: 24px;
  color: ${(p) => p.theme.palette.primary.main};
`;

export const BoxAddressUl = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  @media ${(p) => p.theme.device.tablet} {
    margin-top: 16px;
  }
`;

export const BoxMessengers = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media ${(p) => p.theme.device.tablet} {
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const UlAddress = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 24px;
  color: ${(p) => p.theme.palette.primary.main};
  margin-bottom: 40px;

  @media ${(p) => p.theme.device.tablet} {
    gap: 4px;
    margin-bottom: 34px;
  }
`;

export const MessengersUl = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 144px;
  gap: 32px;

  @media ${(p) => p.theme.device.tablet} {
    width: 213px;
    gap: 55px;
  }
`;

export const StyledAddress = styled.a`
  width: 272px; //TODO: must be 262px, but not fit in one line
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.06em;
  color: #647f29;

  :hover,
  :focus {
    color: ${(p) => p.theme.palette.primary.details};
  }

  @media screen and (min-width: 345px) {
    letter-spacing: -0.02em;
  }
`;

export const StyledContacts = styled.a`
  display: flex;
  justify-content: center;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #647f29;

  :hover,
  :focus {
    color: ${(p) => p.theme.palette.primary.details};
  }
`;

export const TelegramIcon = styled(Telegram)`
  display: inline;
  cursor: pointer;
  width: 28px;
  height: 28px;
  fill: ${(p) => p.theme.palette.primary.main};

  :hover,
  :focus {
    fill: ${(p) => p.theme.palette.primary.details};
  }
`;

export const ViberIcon = styled(Viber)`
  display: inline;
  cursor: pointer;
  width: 24px;
  height: 24px;
  fill: ${(p) => p.theme.palette.primary.main};

  :hover,
  :focus {
    fill: ${(p) => p.theme.palette.primary.details};
  }
`;

export const PhoneIcon = styled(Phone)`
  display: inline;
  cursor: pointer;
  width: 28px;
  height: 28px;
  fill: ${(p) => p.theme.palette.primary.main};

  :hover,
  :focus {
    fill: ${(p) => p.theme.palette.primary.details};
  }
`;
