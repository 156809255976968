import styled from "@emotion/styled";
import { ReactComponent as Pen } from "../../images/pensil.svg";
import { ReactComponent as Right } from "../../images/right.svg";

import { Link } from "react-router-dom";

export const ButtonClose = styled(Link)`
  position: absolute;
  top: 16px;
  left: 24px;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: transparent;

  @media ${(p) => p.theme.device.tablet} {
    left: 32px;
  }
  @media ${(p) => p.theme.device.desktop} {
    left: 80px;
  }
`;

export const IconRight = styled(Right)`
  cursor: pointer;
  display: block;
  width: 10px;
  height: 20px;
  color: #647f29;
`;

export const UserBox = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin-bottom: 32px;
`;

export const UserName = styled.p`
  margin-bottom: 32px;
  width: 156px;

  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0;

  color: #647f29;
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (min-width: 345px) {
    width: 176px;
  }
`;

export const UserMoneyList = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  min-width: 120px;

  @media screen and (min-width: 355px) {
    min-width: 170px;
  }
  @media ${(p) => p.theme.device.tablet} {
    min-width: 200px;
  }
`;

export const UserMoneyItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  padding: 5px;
  border-radius: 24px;

  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  line-height: 24px;
  color: ${(p) => p.theme.palette.primary.text};

  background-color: ${(p) => p.theme.palette.primary.white};
  box-shadow: 0px 4px 11px 0px rgba(100, 127, 41, 0.15),
    4px 0px 11px 0px rgba(100, 127, 41, 0.15);

  @media ${(p) => p.theme.device.mobile} {
    padding: 8px;
  }
`;

export const UserMoneySpan = styled.span`
  font-weight: ${(p) => p.theme.fontWeights.bold};
  color: ${(p) => p.theme.palette.primary.main};
`;

export const UserNoAppointments = styled.p`
  width: 100%;
  text-align: center;
  margin-top: 104px;

  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0;

  color: #647f29;
  @media ${(p) => p.theme.device.tablet} {
    margin-top: 176px;
  }
  @media ${(p) => p.theme.device.desktop} {
    margin-top: 192px;
  }
`;

export const HistoryBox = styled.div`
  width: 100%;
  max-width: 327px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 104px;

  margin-bottom: 55px;

  @media ${(p) => p.theme.device.tablet} {
    max-width: 414px;
    margin-top: 176px;
  }
  @media ${(p) => p.theme.device.desktop} {
    max-width: 522px;
    margin-top: 192px;
  }
`;

export const HistoryHead = styled.p`
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0px;
  padding-right: 72px;
  padding-left: 16px;

  color: #647f29;
`;

export const HistoryHeadSpan = styled.span`
  margin-right: 56.5px;
`;
export const HistoryList = styled.ul`
  /* width: 100%; */
  /* height: 458px; */
  margin-bottom: 0;
  padding-left: 4px;
  overflow-x: hidden;
`;
export const HistoryItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;

  gap: 8px;

  margin-top: 12px;
  padding: 5px;
  border-radius: 24px;

  outline: ${(props) => (props.isFuture ? "2px dashed #FBB336" : "none")};

  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -1px;

  color: #404731;

  background-color: ${(props) => (props.isFuture ? "#ffffff" : "#fbfbfb")};
  box-shadow: 0px 4px 11px 0px rgba(100, 127, 41, 0.15),
    4px 0px 11px 0px rgba(100, 127, 41, 0.15);

  @media ${(p) => p.theme.device.mobile} {
    padding: 16px;
  }

  @media ${(p) => p.theme.device.tablet} {
    padding: 16px;
  }
`;

export const HistoryItemSpan = styled.span`
  min-width: 30px;
`;

export const IconPen = styled(Pen)`
  display: inline-block;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const IconBtn = styled(Link)`
  width: 24px;
  height: 24px;
  background-color: transparent;
`;
