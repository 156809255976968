import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

// export const LogoButton = styled.button`
//   width: 91px;
//   height: 24px;
//   align-items: center;
//   background-color: transparent;
//   position: absolute;
//   z-index: 2;
//   top: 30px;
//   right: 76px;
//   padding: 4px, 14px, 4px, 14px;
//   border-radius: 20px;
//   border: 1px solid rgba(100, 127, 41, 1);

//   @media ${(p) => p.theme.device.desktop} {
//     width: 111px;
//     height: 40px;
//     padding: 12px, 24px, 12px, 24px;
//   }
//   color: ${(p) => p.theme.palette.primary.main};

//   &:focus,
//   &:hover {
//     border: 0.5px solid #fbb336;
//     box-shadow: 0px 3px 12px 0px rgba(100, 127, 41, 0.15);
//     color: #404731;
//   }
// `;

export const Link = styled(NavLink)`
  /* width: 91px;
  height: 24px; */
  /* align-items: center; */
  background-color: transparent;
  position: absolute;
  z-index: 2;
  top: 30px;
  right: 76px;
  padding: 4px 14px 4px 14px;
  border-radius: 20px;
  border: 1px solid rgba(100, 127, 41, 1);

  @media ${(p) => p.theme.device.desktop} {
    /* width: 111px;
    height: 40px; */
    padding: 12px 24px 12px 24px;
  }

  font-family: ${(p) => p.theme.fonts.Comfortaa};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: ${(p) => p.theme.palette.primary.main};

  &:focus,
  &:hover {
    /* border: 0.5px solid #fbb336; */
    box-shadow: 0px 3px 12px 0px rgba(100, 127, 41, 0.15);
    color: #404731;
  }
`;
