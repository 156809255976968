import { createSlice } from "@reduxjs/toolkit";
import { getDates, postDates } from "./operation";

const initialStateDates = {
  dates: [],
  isLoading: false,
};

const datesSlice = createSlice({
  name: "dates",
  initialState: initialStateDates,
  extraReducers: (builder) =>
    builder
      .addCase(getDates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDates.fulfilled, (state, action) => {
        state.dates = action.payload;
        state.isLoading = false;
      })
      .addCase(getDates.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postDates.fulfilled, (state, action) => {
        state.dates = action.payload;
      }),
});

export const datesReducer = datesSlice.reducer;
