import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setGoogleData } from "redux/auth/operation";

// Pass URL
const useGoogleFetch = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleGoogle = async (response) => {
    // console.log(data, "also data");
    // const { access_token } = response;
    // console.log(access_token, "AT");
    fetch(`${process.env.REACT_APP_BACK_BASE_URL}/google/redirect`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ credential: response.access_token }),
    })
      .then((res) => {
        setLoading(false);

        return res.json();
      })
      .then((data) => {
        if (data?.user) {
          dispatch(setGoogleData(data));
          navigate("/cabinet/register", { replace: true });
        }

        throw new Error(data?.message || data);
      })
      .catch((error) => {
        setError(error?.message);
      });
  };
  // const handleGoogle = async (response) => {
  //   setLoading(true);
  //
  //   console.log(response, "RRRRSSSPPP");
  //
  //   fetch(url, {
  //     method: "POST",
  //     mode: "cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //
  //     body: JSON.stringify({ credential: response.credential }),
  //   })
  //     .then((res) => {
  //       setLoading(false);
  //
  //       return res.json();
  //     })
  //     .then((data) => {
  //       if (data?.user) {
  //         dispatch(setGoogleData(data));
  //         navigate("/cabinet/register", { replace: true });
  //       }
  //
  //       throw new Error(data?.message || data);
  //     })
  //     .catch((error) => {
  //       // console.log('~error useGoogleFetch.js [33]:', error.message); // TODO: fix error
  //       setError(error?.message);
  //     });
  // };

  return { loading, error, handleGoogle };
  // return { loading, error };
};
// const useGoogleFetch = (url) => {
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const navigate = useNavigate();
//
//   const handleGoogle = async (response) => {
//     setLoading(true);
//
//     console.log(response, 'RRRRSSSPPP')
//
//     fetch(url, {
//       method: 'POST',
//       mode: 'cors',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//
//       body: JSON.stringify({ credential: response.credential }),
//     })
//       .then((res) => {
//         setLoading(false);
//
//         return res.json();
//       })
//       .then((data) => {
//         if (data?.user) {
//
//           dispatch(setGoogleData(data));
//           navigate('/cabinet/register', { replace: true });
//         }
//
//         throw new Error(data?.message || data);
//       })
//       .catch((error) => {
//         // console.log('~error useGoogleFetch.js [33]:', error.message); // TODO: fix error
//         setError(error?.message);
//       });
//   };
//
//   return { loading, error, handleGoogle };
// };
//
export default useGoogleFetch;
