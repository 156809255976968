import { Button } from "./Buttons.styled";

const Buttons = ({ title, type, disabled = false, handleFunction, styled }) => {
  return (
    <Button
      type={type}
      disabled={disabled}
      onClick={handleFunction}
      style={styled}
    >
      {title}
    </Button>
  );
};

export default Buttons;
