import { useOutletContext } from "react-router-dom";

import { List } from "./FinanceAdmin.styled";
import FinanceUserItem from "./FinanceUserItem";
import { useEffect, useMemo } from "react";

const BalanceAdmin = () => {
  const { filteredUsers, usersState, setIsDeposit } = useOutletContext();

  useEffect(() => setIsDeposit(false));

  const sortedUsers = useMemo(
    () =>
      filteredUsers
        .slice()
        .sort((a, b) => a.nickName.localeCompare(b.nickName)),
    [filteredUsers]
  );

  return (
    <>
      {usersState.length > 0 && filteredUsers && (
        <List>
          {sortedUsers.map((user) => (
            <FinanceUserItem key={user._id} user={user} isDeposit={false} />
          ))}
        </List>
      )}
    </>
  );
};

export default BalanceAdmin;
