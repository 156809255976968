import styled from "@emotion/styled";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { ReactComponent as Google } from "../../images/google.svg";
import check from "../../images/check.png";

export const PhoneIn = styled(PhoneInput)`
  width: 100%;
  height: 44px;
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};

  border-radius: 20px;
  border: ${(p) => p.theme.border[3]};
  outline: none;

  & .form-control {
    width: 100%;
    height: 100%;

    font-family: ${(p) => p.theme.fonts.Manrope};
    font-size: ${(p) => p.theme.fontSizes[4]};

    border-radius: 20px;
    border: 0px solid #cacaca;

    &.open {
      border-radius: 20px;
    }
  }

  & .react-tel-input,
  & .flag-dropdown {
    border-radius: 20px 0 0 20px;
    border: none; // tut zminu
    background: #fff;
    &.open {
      border-radius: 20px 0 0 20px;
      border: none; // tut zminu
      background: #fff;
    }
  }

  & .selected-flag {
    border-radius: 20px 0 0 20px;
    &.open {
      border-radius: 20px 0 0 20px;
    }
  }
  & .flag-dropdown.open .selected-flag {
    border-radius: 20px 0 0 20px;
  }
  & .flag-dropdown,
  & .flag-dropdown.open {
    border-radius: 20px 0 0 20px;

    &.country-list {
      z-index: 100;
    }
  }

  & .react-tel-input,
  .invalid-number-message {
    position: absolute;
    z-index: 1;
    font-size: 14px;
    left: 16px;
    top: 46px;
    background: transparent;
    padding: 0 2px;
    color: #de0000;
  }
`;

export const Box = styled.div`
  padding: 0px 0px 104px 0px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 327px;
  margin-left: auto;
  margin-right: auto;

  @media ${(p) => p.theme.device.tablet} {
    max-width: 414px;
  }
  @media ${(p) => p.theme.device.desktop} {
    max-width: 522px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Label = styled.label`
  display: block;
  width: 100%;
  padding-left: 6px;
  margin-bottom: 2px;

  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[3]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 1.2;
  text-align: left;
  color: ${(p) => p.theme.palette.primary.main};
`;

export const Span = styled.span`
  display: block;
  text-align: center;
  margin: 0 auto;
  padding: 8px;
`;

export const IconGoogle = styled(Google)`
  position: absolute;
  bottom: 8px;
  left: 18%;
  display: inline;
  cursor: pointer;
  width: 32px;
  height: 32px;

  @media ${(p) => p.theme.device.mobile} {
    bottom: 8px;
    left: 22%;
  }
  @media ${(p) => p.theme.device.tablet} {
    bottom: 8px;
    left: 27%;
  }
  @media ${(p) => p.theme.device.desktop} {
    bottom: 8px;
    left: 30%;
  }
`;

export const TextHelper = styled.p`
  position: absolute;
  top: 64px;
  left: 16px;

  font-size: 12px;
  line-height: 14px;
`;

export const Text = styled.p`
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 1;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 16px;
  color: ${(p) => p.theme.palette.primary.main};
`;

export const BoxRadio = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 62px;

  &:nth-last-of-type(2) {
    margin-bottom: 48px;
  }
`;

export const RadioLable = styled.span`
  display: flex;
  align-items: center;
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 1.25;
  color: ${(p) => p.theme.palette.primary.main};
  margin-left: 16px;

  &:before {
    content: "";
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: +1px;
    left: 0;
    width: 24px;
    height: 24px;
    border: ${(p) => p.theme.border[7]};
    border-radius: 4px;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 44px;

  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};

  padding-left: 16px;
  padding-right: 16px;
  opacity: 0.8;
  flex: none;
  order: 1;
  flex-grow: 0;

  border-radius: 20px;
  border: ${(p) => p.theme.border[3]};
  outline: none;
`;

export const AgreeText = styled.span`
  display: flex;
  align-items: center;
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[2]};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  line-height: calc(20 / 12);
  color: ${(p) => p.theme.palette.primary.main};
  margin-left: 16px;

  @media ${(p) => p.theme.device.tablet} {
    font-size: ${(p) => p.theme.fontSizes[3]};
  }

  &::before {
    content: "";
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: -1px;
    left: 0;
    width: 24px;
    height: 24px;
    border: ${(p) => p.theme.border[7]};
    border-radius: 4px;
  }
`;

export const Agree = styled.span`
  display: flex;
  align-items: center;
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[3]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 1;
  color: ${(p) => p.theme.palette.primary.main};
  margin-left: 16px;

  &::before {
    content: "";
    box-sizing: border-box;
    display: block;
    position: absolute;
    bottom: 9px;
    left: 0;
    width: 24px;
    height: 24px;
    border: ${(p) => p.theme.border[7]};
    border-radius: 4px;
  }
`;

export const AgreeTextLink = styled.a`
  color: #1a89c7;
`;

export const buttonGoogle = {
  display: "inline-flex", //TODO: new
  alignItems: "center", //TODO: new
  paddingLeft: "5px", //TODO: new
  width: "100%",
  height: "48px",
  borderRadius: "24px",
  border: "1px solid #FBB336",
  ml: "auto",
  backgroundColor: "#FFFFFF",
  color: "#404731",
  [`&:hover`]: {
    backgroundColor: "#85B53A",
    color: "#FFFFFF",
  },
  [`&:focus`]: {
    backgroundColor: "#85B53A",
    color: "#FFFFFF",
  },
};

export const CancelBtn = styled.button`
  display: inline-block;
  margin-left: auto;
  margin-right: auto;

  background-color: inherit;
  border: none;

  color: #85b53a;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
`;

export const PasswordToggleIcon = styled.span`
  position: absolute;
  top: 30px;
  right: 16px;
  cursor: pointer;
`;

export const ButtonForgot = styled.button`
  font-family: "Manrope";
  position: absolute;
  top: 160px;
  left: 6px;

  font-size: 12px;
  font-weight: 400;
  line-height: calc(12 / 20);
  letter-spacing: 0em;

  padding: 0;
  background: transparent;
  color: #1a89c7;

  border: none;

  @media ${(p) => p.theme.device.desktop} {
    font-size: 14px;
  }
`;

export const WrapperFormForgot = styled.div`
  position: relative;
  width: 100%;
  max-width: 327px;
  margin-right: auto;
  margin-left: auto;

  @media ${(p) => p.theme.device.tablet} {
    max-width: 414px;
  }
  @media ${(p) => p.theme.device.desktop} {
    max-width: 522px;
  }
`;

export const RadioContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
`;

export const RadioInput = styled.input`
  display: none;

  &:checked + span:after {
    content: "";
    background: url(${check}) no-repeat center;
    display: inline-block;
    position: absolute;
    top: 0.5px;
    left: 0px;
    width: 25px;
    height: 25px;
    /* transform: translateY(-50%); */
  }
`;

export const RadioInputAgree = styled.input`
  display: none;

  &:checked + span:after {
    content: "";
    background: url(${check}) no-repeat center;
    display: inline-block;
    position: absolute;
    top: -1px;
    left: 0px;
    width: 25px;
    height: 25px;
    /* transform: translateY(-50%); */
  }
`;
