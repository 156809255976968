import * as React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { ThemeProvider } from "@mui/material";

import CustomAdapter from "helpers/CustomDateAdapter";
import { useSelector } from "react-redux";
import { selectDisabled } from "redux/dates/selector";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { customTheme } from "components/MuiBirthdayTimePicker/MuiDialogTheme";

dayjs.extend(customParseFormat);

const MuiTimePickerViews = ({
  timePickerValue,
  setTimePickerValue,
  appointmentType,
}) => {
  const disabled = useSelector(selectDisabled);

  const disabledDays = (date) => {
    const disabledDates = disabled[appointmentType + "Data"].map((d) =>
      dayjs(d.day, "MM-DD-YYYY")
    );

    return disabledDates.some((d) => d.isSame(date, "day"));
  };

  return (
    <ThemeProvider theme={customTheme}>
      <LocalizationProvider dateAdapter={CustomAdapter} adapterLocale="uk">
        <DemoContainer
          components={["MobileDatePicker"]}
          sx={{
            paddingTop: 0,
            "@media (min-width: 1200px)": {
              flexDirection: "column",
            },
          }}
        >
          <DemoItem>
            <MobileDatePicker
              views={["year", "month", "day"]}
              value={timePickerValue}
              onChange={setTimePickerValue}
              disablePast
              shouldDisableDate={disabledDays}
              sx={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
                "& .MuiInputBase-input": {
                  padding: "10px 0px 10px 2px",
                  cursor: "pointer",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default MuiTimePickerViews;
