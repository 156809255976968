import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { StyledMain } from "./Layout.styled";

const Layout = () => {
  return (
    <>
      <Header />
      <StyledMain>
        <Suspense>
          <Outlet />
        </Suspense>
      </StyledMain>
      <Footer />
    </>
  );
};

export default Layout;
