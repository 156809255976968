import React from "react";
import { BoxContent, BoxFooter, LogoBox } from "./Footer.styled";

import Address from "./Address/Address.js";
import Schedule from "./Schedule/Schedule.js";
import Logo from "components/Logo";

const Footer = () => {
  return (
    <BoxFooter>
      <LogoBox>
        <Logo />
      </LogoBox>
      <BoxContent>
        <Schedule />
        <Address />
      </BoxContent>
    </BoxFooter>
  );
};

export default Footer;
