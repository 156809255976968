import styled from "@emotion/styled";

export const LoaderSpan = styled.span`
  display: inline-block;
  /* opacity: ${({ visible }) => (visible ? 1 : 0)}; */

  position: absolute;
  top: ${({ isNoCenter }) => (isNoCenter ? "70%" : "50%")};
  left: 50%;

  font-size: ${({ isSmall }) => (isSmall ? "8px" : "18px")};
  width: ${({ isSmall }) => (isSmall ? "8px" : "14px")};
  height: ${({ isSmall }) => (isSmall ? "8px" : "14px")};

  border-radius: 50%;

  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);

  @keyframes mulShdSpin {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em rgb(100, 127, 41),
        1.8em -1.8em 0 0em rgba(100, 127, 41, 0.2),
        2.5em 0em 0 0em rgba(100, 127, 41, 0.2),
        1.75em 1.75em 0 0em rgba(100, 127, 41, 0.2),
        0em 2.5em 0 0em rgba(100, 127, 41, 0.2),
        -1.8em 1.8em 0 0em rgba(100, 127, 41, 0.2),
        -2.6em 0em 0 0em rgba(100, 127, 41, 0.5),
        -1.8em -1.8em 0 0em rgba(100, 127, 41, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(100, 127, 41, 0.7),
        1.8em -1.8em 0 0em rgb(100, 127, 41),
        2.5em 0em 0 0em rgba(100, 127, 41, 0.2),
        1.75em 1.75em 0 0em rgba(100, 127, 41, 0.2),
        0em 2.5em 0 0em rgba(100, 127, 41, 0.2),
        -1.8em 1.8em 0 0em rgba(100, 127, 41, 0.2),
        -2.6em 0em 0 0em rgba(100, 127, 41, 0.2),
        -1.8em -1.8em 0 0em rgba(100, 127, 41, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(100, 127, 41, 0.5),
        1.8em -1.8em 0 0em rgba(100, 127, 41, 0.7),
        2.5em 0em 0 0em rgb(100, 127, 41),
        1.75em 1.75em 0 0em rgba(100, 127, 41, 0.2),
        0em 2.5em 0 0em rgba(100, 127, 41, 0.2),
        -1.8em 1.8em 0 0em rgba(100, 127, 41, 0.2),
        -2.6em 0em 0 0em rgba(100, 127, 41, 0.2),
        -1.8em -1.8em 0 0em rgba(100, 127, 41, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(100, 127, 41, 0.2),
        1.8em -1.8em 0 0em rgba(100, 127, 41, 0.5),
        2.5em 0em 0 0em rgba(100, 127, 41, 0.7),
        1.75em 1.75em 0 0em rgb(100, 127, 41),
        0em 2.5em 0 0em rgba(100, 127, 41, 0.2),
        -1.8em 1.8em 0 0em rgba(100, 127, 41, 0.2),
        -2.6em 0em 0 0em rgba(100, 127, 41, 0.2),
        -1.8em -1.8em 0 0em rgba(100, 127, 41, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(100, 127, 41, 0.2),
        1.8em -1.8em 0 0em rgba(100, 127, 41, 0.2),
        2.5em 0em 0 0em rgba(100, 127, 41, 0.5),
        1.75em 1.75em 0 0em rgba(100, 127, 41, 0.7),
        0em 2.5em 0 0em rgb(100, 127, 41),
        -1.8em 1.8em 0 0em rgba(100, 127, 41, 0.2),
        -2.6em 0em 0 0em rgba(100, 127, 41, 0.2),
        -1.8em -1.8em 0 0em rgba(100, 127, 41, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(100, 127, 41, 0.2),
        1.8em -1.8em 0 0em rgba(100, 127, 41, 0.2),
        2.5em 0em 0 0em rgba(100, 127, 41, 0.2),
        1.75em 1.75em 0 0em rgba(100, 127, 41, 0.5),
        0em 2.5em 0 0em rgba(100, 127, 41, 0.7),
        -1.8em 1.8em 0 0em rgb(100, 127, 41),
        -2.6em 0em 0 0em rgba(100, 127, 41, 0.2),
        -1.8em -1.8em 0 0em rgba(100, 127, 41, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(100, 127, 41, 0.2),
        1.8em -1.8em 0 0em rgba(100, 127, 41, 0.2),
        2.5em 0em 0 0em rgba(100, 127, 41, 0.2),
        1.75em 1.75em 0 0em rgba(100, 127, 41, 0.2),
        0em 2.5em 0 0em rgba(100, 127, 41, 0.5),
        -1.8em 1.8em 0 0em rgba(100, 127, 41, 0.7),
        -2.6em 0em 0 0em rgb(100, 127, 41),
        -1.8em -1.8em 0 0em rgba(100, 127, 41, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(100, 127, 41, 0.2),
        1.8em -1.8em 0 0em rgba(100, 127, 41, 0.2),
        2.5em 0em 0 0em rgba(100, 127, 41, 0.2),
        1.75em 1.75em 0 0em rgba(100, 127, 41, 0.2),
        0em 2.5em 0 0em rgba(100, 127, 41, 0.2),
        -1.8em 1.8em 0 0em rgba(100, 127, 41, 0.5),
        -2.6em 0em 0 0em rgba(100, 127, 41, 0.7),
        -1.8em -1.8em 0 0em rgb(100, 127, 41);
    }
  }
`;
