import styled from "@emotion/styled";

export const BoxLogo = styled.div`
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 0px;

  display: flex;
  flex-direction: row;

  @media ${(p) => p.theme.device.tablet} {
    top: 20px;
    right: 10px;
  }

  @media ${(p) => p.theme.device.desktop} {
    top: 5px;
    right: 0px;
  }
`;
