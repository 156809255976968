import { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";

import { register } from "redux/auth/operation";
import schema from "utils/schema/RegisterSchema";
import usePasswordToggle from "hooks/usePasswordToggle";
import { transformNicknameUppercase } from "helpers/validateNicknameInput";

import GoogleButton from "../../googleButton/GoogleButton";
import Buttons from "components/Buttons/Buttons";
import {
  Box,
  Input,
  Text,
  RadioLable,
  BoxRadio,
  Form,
  Label,
  Span,
  TextHelper,
  InputContainer,
  AgreeText,
  PasswordToggleIcon,
  PhoneIn,
  RadioContainer,
  RadioInput,
  RadioInputAgree,
  IconGoogle,
} from "../AuthForm.styled";

import MuiBirthdayTimePicker from "components/MuiBirthdayTimePicker/MuiBirthdayTimePicker";
import {
  styledRegistrationBirthday,
  styledRegistrationBirthdayError,
} from "components/MuiBirthdayTimePicker/MuiBirthdayTimePicker.styled";

const RegisterForm = () => {
  const dispatch = useDispatch();
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [touchBirthday, setTouchBirthday] = useState(false);
  // const [closeWindowBirthday, setCloseWindowBirthday] = useState(false);

  const formik = useFormik({
    initialValues: {
      nickName: "",
      birthday: null,
      phone: "",
      email: "",
      password: "",
      notifications: "",
      agree: false,
    },
    validationSchema: schema,
    onSubmit: (values, formikHelpers) => {
      const submitValues = !values.email
        ? {
            nickName: values.nickName,
            birthday: values.birthday,
            phone: "+" + values.phone,
            password: values.password,
            notifications: values.notifications,
          }
        : {
            nickName: values.nickName,
            birthday: values.birthday,
            phone: "+" + values.phone,
            password: values.password,
            notifications: values.notifications,
            email: values.email,
          };
      dispatch(register(submitValues));
      // formikHelpers.resetForm();
    },
    validateOnBlur: true, // Валідація відбувається після втрати фокусу
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = formik;

  return (
    <Box>
      <Form onSubmit={handleSubmit} autoComplete="off">
        <InputContainer>
          <Label
            htmlFor="nickName"
            style={errors.nickName && touched.nickName && { color: "#EA4335" }}
          >
            *ПІБ
          </Label>
          <Input
            id="nickName"
            name="nickName"
            type="text"
            value={values.nickName}
            onChange={(e) => transformNicknameUppercase(e, setFieldValue)}
            onBlur={handleBlur}
            autoComplete="off"
            style={
              errors.nickName && touched.nickName && { borderColor: "#EA4335" }
            }
          />
          {errors.nickName && touched.nickName && (
            <TextHelper style={{ color: "#EA4335" }}>
              {errors.nickName}
            </TextHelper>
          )}
        </InputContainer>
        <InputContainer>
          {errors.birthday && touchBirthday ? (
            <Label htmlFor="birthday" style={{ color: "#EA4335" }}>
              *Дата народження
            </Label>
          ) : (
            <Label htmlFor="birthday">*Дата народження</Label>
          )}
          <MuiBirthdayTimePicker
            timePickerValue={values.birthday}
            setTimePickerValue={(value) => setFieldValue("birthday", value)}
            // error={errors.birthday}
            // pickerStyles={errors.birthday && touchBirthday} : {styledRegistrationBirthday} ? {styledRegistrationBirthdayError}
            pickerStyles={
              (errors.birthday &&
                touchBirthday &&
                styledRegistrationBirthdayError) ||
              styledRegistrationBirthday
            }
            setValueOpen={setTouchBirthday}
          />
          {errors.birthday && touchBirthday && (
            <TextHelper style={{ color: "#EA4335" }}>
              {errors.birthday}
            </TextHelper>
          )}
        </InputContainer>
        <InputContainer>
          <Label
            htmlFor="phone"
            style={errors.phone && touched[undefined] && { color: "#EA4335" }}
          >
            *Телефон
          </Label>
          <PhoneIn
            country={"ua"}
            id="phone"
            name="phone"
            type="tel"
            value={values.phone}
            onChange={(value) => setFieldValue("phone", value)}
            onBlur={handleBlur}
            placeholder="+380991234567"
            preferredCountries={["ua"]}
            autoComplete="off"
            excludeCountries={["ru"]}
            style={
              errors.phone && touched[undefined] && { borderColor: "#EA4335" }
            }
          />
          {errors.phone && touched[undefined] && (
            <TextHelper style={{ color: "#EA4335" }}>{errors.phone}</TextHelper>
          )}
        </InputContainer>
        <InputContainer>
          <Label
            htmlFor="email"
            style={errors.email && touched.email && { color: "#EA4335" }}
          >
            Пошта
          </Label>
          <Input
            id="email"
            name="email"
            type="email"
            multiline
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            style={errors.email && touched.email && { borderColor: "#EA4335" }}
          />
          {errors.email && touched.email && (
            <TextHelper style={{ color: "#EA4335" }}>{errors.email}</TextHelper>
          )}
        </InputContainer>
        <InputContainer>
          <Label
            htmlFor="password"
            style={errors.password && touched.password && { color: "#EA4335" }}
          >
            *Пароль
          </Label>
          <Input
            id="password"
            name="password"
            type={PasswordInputType}
            multiline
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            style={
              errors.password && touched.password
                ? { borderColor: "#EA4335", paddingRight: "42px" }
                : { paddingRight: "42px" }
            }
          />
          <PasswordToggleIcon>{ToggleIcon}</PasswordToggleIcon>

          {errors.password && touched.password && (
            <TextHelper style={{ color: "#EA4335" }}>
              {errors.password}
            </TextHelper>
          )}
        </InputContainer>
        <Text
          style={
            errors.notifications && { color: "#EA4335" } // touched.notifications &&
          }
        >
          *Отримувати сповіщення:
        </Text>
        <BoxRadio>
          <RadioContainer>
            <RadioInput
              type="radio"
              name="notifications"
              value="telegram"
              checked={values.notifications === "telegram"}
              onChange={() => setFieldValue("notifications", "telegram")}
            />
            <RadioLable
              style={{ "&::before": { border: "2px solid #FBB336" } }}
            >
              Telegram
            </RadioLable>
          </RadioContainer>
          <RadioContainer>
            <RadioInput
              type="radio"
              name="notifications"
              value="viber"
              checked={values.notifications === "viber"}
              onChange={() => setFieldValue("notifications", "viber")}
            />
            <RadioLable
              style={{ "&::before": { border: "2px solid #FBB336" } }}
            >
              Viber
            </RadioLable>
          </RadioContainer>
        </BoxRadio>
        <BoxRadio>
          <RadioContainer>
            <RadioInputAgree
              type="checkbox"
              name="agree"
              value="true"
              checked={values.agree === true}
              onChange={handleChange}
            />
            <AgreeText style={{ "&::before": { border: "2px solid #FBB336" } }}>
              *Цим надаю згоду на обробку та зберігання в додатку моїх вище
              зазначених персональних даних.
            </AgreeText>
          </RadioContainer>
        </BoxRadio>

        <Buttons
          type="submit"
          title="Зареєструватися"
          disabled={!(formik.isValid && formik.dirty && values.agree)}
        />
        <Span>або</Span>
        <GoogleButton>
          Зареєструватися з Google{" "}
          <IconGoogle
            style={{
              left: "clamp(0.625rem, -1.3393rem + 9.8214vw, 7.5rem)",
            }}
          />{" "}
        </GoogleButton>
      </Form>
    </Box>
  );
};
export default RegisterForm;
