import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const getAppointments = createAsyncThunk(
  "appointments/get",
  async (_, thunkAPI) => {
    try {
      const r = await axios.get("/appointments/by-date");
      return r.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const appointmentUpdate = createAsyncThunk(
  "appointments/appointmentUpdate",
  async (credentials, thunkAPI) => {
    try {
      const r = await axios.patch("/appointments", credentials);
      toast.success("Успішно змінено");
      return r.data;
    } catch (error) {
      toast.error(error.response?.data?.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addAppointment = createAsyncThunk(
  "appointments/addAppointment",
  async (text, thunkAPI) => {
    try {
      const r = await axios.post("/appointments", text);

      return r.data;
    } catch (error) {
      toast.error(error.response?.data?.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteAppointment = createAsyncThunk(
  "appointments/deleteAppointment",
  async (appointmentId, thunkAPI) => {
    try {
      await axios.delete(`/appointments/${appointmentId}`);
      return {
        appointmentId: appointmentId,
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const setIsNewAppointmentToFalse = createAction(
  "appointments/setIsNewAppointmentToFalse"
);
