import {
  object,
  string,
} from "yup";

import { validatePhoneNumber } from "helpers/validatePhoneNumber";
import { validateNicknameInput } from "helpers/validateNicknameInput";

const schema = object().shape({
  nickName: string()
    .test("nickName", "Невірно прописано ПІБ", function (value) {
      const result = validateNicknameInput(value);

      if (typeof result === "string") {
        return this.createError({ path: "nickName", message: result });
      }
      return true;
    })
    .required("Поле обов'язкове")
    .trim(),
  birthday: string().required("Поле обов'язкове"),
  // birthday: date("Введіть корректну дату")
  //   // .min(min, `Дата має бути не раніше за ${min}`)
  //   .transform((value, originalValue) => {
  //     return parse(originalValue, "dd.MM.yyyy", new Date());
  //   })
  //   .typeError("Введіть корректну дату, наприклад 01.01.1999")
  //   .required("Поле обов'язкове"),

  phone: string()
    .test("phone", "Невірний номер телефону", function (value) {
      const result = validatePhoneNumber(value); // Викликаємо вашу функцію для перевірки

      // Якщо результат перевірки є рядком, то це повідомлення про помилку
      if (typeof result === "string") {
        return this.createError({ path: "phone", message: result });
      }
      return true;
    })
    .required("Номер телефону обов'язковий"),

  email: string().email("Невалідний email").lowercase().trim(),
  password: string()
    .min(6, "Введіть мінімум 6 символів ")
    .max(32, "Пароль має бути до 32 символів")
    .required("Поле обов'язкове")
    .trim(),
  notifications: string().required("Поле обов'язкове"),
  agree: string().required("Поле обов'язкове"),
});

export default schema;
