import styled from "@emotion/styled";

export const Button = styled.button`
  display: block;
  font-family: "Manrope";
  font-weight: 500;
  font-size: 16px;
  line-height: calc(24 / 16);

  /* width: calc(100% - 48px); */
  width: 100%;
  max-width: 327px;
  margin-top: 56px;
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  box-shadow: 0px 4px 20px 0px rgba(100, 127, 41, 0.25);

  background: #85b53a;
  border-radius: 24px;
  color: #ffffff;

  &:focus,
  &:hover {
    background: #a9ca77;
  }

  &:disabled {
    background: #a7a8a6;
    cursor: auto;
  }

  @media ${(p) => p.theme.device.tablet} {
    max-width: 414px;
  }
  @media ${(p) => p.theme.device.desktop} {
    max-width: 522px;
  }
`;
