import styled from "@emotion/styled";

export const BoxFooter = styled.footer`
  padding: 16px 24px 24px 24px;
  min-height: 506px;
  height: 100%;
  border-top: 1px solid #e4e9d8;

  @media ${(p) => p.theme.device.tablet} {
    padding: 24px 32px 48px 32px;
    min-height: 180px;
  }

  @media ${(p) => p.theme.device.desktop} {
    padding: 32px 188px 48px 188px;
  }
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 78px;

  @media ${(p) => p.theme.device.tablet} {
    margin-top: 75px;
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${(p) => p.theme.device.desktop} {
    margin-top: 80px;
  }
`;

export const LogoBox = styled.div`
  position: relative;
  z-index: 0;
  top: -18px;
  left: -24px;

  @media ${(p) => p.theme.device.tablet} {
    top: -34px;
    left: -34px;
  }

  @media ${(p) => p.theme.device.desktop} {
    left: -194px;
  }
`;

export const MessengersUl = styled.ul`
  display: inline-flex;
  flex-direction: row;
`;
