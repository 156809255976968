// import { store } from "./redux/store";
import axios from "axios";

import { toast } from "react-toastify";
import { totalLogOut } from "redux/auth/operation";

// NOTE: new code reset login on jwt token expired
const unauthorized = 401;
const expired = "jwt expired";
let count = 0;

// const { dispatch } = store; // direct access to redux store.
export const axiosMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        //

        const { status } = error.response;
        const { message } = error.response.data;
        // console.log(message, "errrrr");
        if (status === unauthorized && message === expired) {
          // console.log("yahhooo!!");

          dispatch(totalLogOut());
          if (count === 0) {
            toast.error("Щось пішло не так. Будь ласка, залогуйтесь.");
            count += 1;
          }
        }
        return Promise.reject(error);
      }
    );
    return next(action);
  };
