import { useEffect, useState } from "react";
import { userUpdateByAdmin } from "redux/auth/operation";
import { useDispatch } from "react-redux";
import {
  Form,
  Button,
  IconSave,
  BoxItem,
  Item,
  Name,
  Number,
  InputContainer,
  Input,
  TelegramIcon,
  ViberIcon,
  More,
  ButtonMore,
} from "./FinanceAdmin.styled";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const FinanceUserItem = ({ user, isDeposit }) => {
  const finance = isDeposit ? user.deposit : user.balance;
  const [currentBalance, setCurrentBalance] = useState(finance ? finance : 0);
  const [showBtn, setShowBtn] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    setShowBtn(currentBalance !== finance && currentBalance !== 0);
  }, [currentBalance, finance]);

  const handleSubmit = (e, id, balance) => {
    e.preventDefault();
    isDeposit
      ? dispatch(userUpdateByAdmin({ id, deposit: balance ? balance : 0 }))
      : dispatch(userUpdateByAdmin({ id, balance: balance ? balance : 0 }));

    setShowBtn(false);
  };

  const handleChangeBalance = (e) => {
    if (/^-?\d*$/.test(e.target.value)) {
      setCurrentBalance(e.target.value);
    } else {
      toast.error("Введіть число");
      setShowBtn(false);
    }
  };

  let userDisplayedIcon = null;
  if (user.telegram && !user.viber) {
    userDisplayedIcon = "telegram";
  } else if (user.telegram && user.notifications === "telegram") {
    userDisplayedIcon = "telegram";
  } else if (!user.telegram && user.viber) {
    userDisplayedIcon = "viber";
  } else if (user.viber && user.notifications === "viber") {
    userDisplayedIcon = "viber";
  }

  return (
    <Item key={user._id} isDeposit={isDeposit}>
      <BoxItem>
        <Name>{user.nickName}</Name>
        <Number>
          {user.phone} {userDisplayedIcon === "telegram" && <TelegramIcon />}
          {userDisplayedIcon === "viber" && <ViberIcon />}
        </Number>
      </BoxItem>
      <Form onSubmit={(e) => handleSubmit(e, user._id, currentBalance)}>
        <InputContainer>
          <Input
            name="balance"
            value={currentBalance}
            onChange={handleChangeBalance}
            autoComplete="off"
            type="number"
          />
          {showBtn && (
            <Button type="submit">
              <IconSave />
            </Button>
          )}
        </InputContainer>
      </Form>
      <ButtonMore
        to={`/cabinet/finance/${user._id}`}
        state={{ from: location }}
      >
        <More />
      </ButtonMore>
    </Item>
  );
};

export default FinanceUserItem;
