import styled from "@emotion/styled";

export const NoList = styled.p`
  margin-top: 48px;
  margin-left: auto;
  margin-right: auto;
  width: 300px;

  text-align: center;
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 1.6;
  color: ${(p) => p.theme.palette.primary.main};
`;

export const CalendarBox = styled.div`
  @media (min-width: 1440px) {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
`;
