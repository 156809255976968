// import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom/dist";
import { Navigate } from "react-router-dom";

import dayjs from "dayjs";
import { lazy } from "react";

import "./App.css";
import { GlobalStyles } from "./styles/GlobalStyles";
import { theme } from "./styles/theme";
import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuth } from "./hooks/useAuth";
import useLocalStorage from "hooks/userLocalStorage";
import { refreshCurrentUser } from "./redux/auth/operation";
import { selectUser } from "redux/auth/selector";
// import { getAppointments } from "redux/appointments/operation";
import { getDates } from "redux/dates/operation";
import { RadioBtnContext } from "context/radioBtnContext";
import UpdateUserAppointmentDetails from "components/UpdateUserAppointmentDetails/UpdateUserAppointmentDetails";

import ScrollToTopFab from "components/scrollToTop";
import Loader from "components/Loader/Loader";
import RestrictedRoute from "components/RestrictedRoute";
import PrivateRoute from "components/PrivateRoute";
import SigninForm from "components/AuthForm/SigninForm/SigninForm";
import OnlineCalendar from "components/OnlineCalendar/OnlineCalendar";
import OfflineCalendar from "components/OfflineCalendar/OfflineCalendar";
import Layout from "components/Layout";
import RegisterForm from "components/AuthForm/RegisterForm/RegisterForm";
import DepositAdmin from "components/FinanceAdmin/DepositAdmin";
import BalanceAdmin from "components/FinanceAdmin/BalanceAdmin";
import AdminUsersHistory from "pages/AdminUsersHistory/AdminUsersHistory";

// NOTE: new yvg
import { GoogleOAuthProvider } from "@react-oauth/google";
const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// NOTE: new yvg end

const WelcomePage = lazy(() => import("./pages/WelcomePage/WelcomePage"));
const AuthPage = lazy(() => import("./pages/AuthPage/AuthPage"));
const CalendarPage = lazy(() => import("./pages/CalendarPage/CalendarPage"));
const PricePage = lazy(() => import("./pages/PricePage/PricePage"));
const ContactsPage = lazy(() => import("./pages/ContactsPage/ContactsPage"));
const AboutMePage = lazy(() => import("./pages/AboutMePage/AboutMePage"));
const CabinetPage = lazy(() => import("./pages/CabinetPage/CabinetPage"));
const PersonalData = lazy(() => import("./pages/PersonalData/PersonalData"));
const Reception = lazy(() => import("./pages/Reception/Reception"));
const FinancePage = lazy(() => import("./pages/FinancePage/FinancePage"));
const AdminCabinetPage = lazy(() =>
  import("./pages/AdminPage/AdminCabinetPage")
);
const ReceptionPage = lazy(() => import("./pages/ReceptionPage/ReceptionPage"));
const GoogleRegisterPage = lazy(() =>
  import("./pages/GoogleRegisterPage/GoogleRegisterPage")
);
const ChangePasswordPage = lazy(() =>
  import("./pages/ChangePasswordPage/ChangePasswordPage")
);
const AddDeposit = lazy(() => import("pages/AddDeposit/AddDeposit"));
const SuccessfulRecordingPage = lazy(() =>
  import("pages/SuccessfulRecordingPage/SuccessfulRecordingPage")
);

function App() {
  const dispatch = useDispatch();
  const { isRefreshing } = useAuth();
  const { role } = useSelector(selectUser);
  const [radioChoice, setRadioChoice] = useLocalStorage("radioBtnChoise", null);
  const [appointmentUpdateLS, setAppointmentUpdateLS] = useLocalStorage(
    "appointment",
    null
  );

  const [dayValue, setDayValue] = useState(dayjs());
  const [appointmentRadioChoice, setAppointmentRadioChoice] = useState("");
  const [appointmentType, setAppointmentType] = useState("online");
  const [idAppointment, setIdAppointment] = useState("");
  const [locationFirstApp, setLocationFirstApp] = useState(null);

  useEffect(() => {
    dispatch(refreshCurrentUser());

    // dispatch(getAppointments());

    dispatch(getDates());
  }, [dispatch]);

  const admin = role === "admin";
  return (
    // NOTE: new yvg
    <GoogleOAuthProvider clientId={ REACT_APP_GOOGLE_CLIENT_ID }>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {isRefreshing ? (
          <Loader isSmall={false} />
        ) : (
          <>
            <RadioBtnContext.Provider
              value={{
                radioChoice,
                setRadioChoice,
                dayValue,
                setDayValue,
                appointmentRadioChoice,
                setAppointmentRadioChoice,
                appointmentType,
                setAppointmentType,
                idAppointment,
                setIdAppointment,
                locationFirstApp,
                setLocationFirstApp,
                appointmentUpdateLS,
                setAppointmentUpdateLS,
              }}
            >
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route
                    index
                    element={
                      <RestrictedRoute
                        component={WelcomePage}
                        redirectTo="/cabinet"
                      />
                    }
                  />
                  <Route
                    path="/auth"
                    element={
                      <RestrictedRoute
                        component={AuthPage}
                        redirectTo="/cabinet"
                        myProp="auth"
                      />
                    }
                  >
                    <Route
                      path="signin"
                      element={
                        <RestrictedRoute
                          component={SigninForm}
                          redirectTo="/cabinet"
                        />
                      }
                    />
                    <Route
                      path="register"
                      element={
                        <RestrictedRoute
                          component={RegisterForm}
                          redirectTo="/cabinet"
                        />
                      }
                    />
                  </Route>
                  <Route
                    path="reception"
                    element={
                      <RestrictedRoute
                        component={ReceptionPage}
                        redirectTo="/success"
                      />
                    }
                  />
                  <Route
                    path="authReception"
                    element={
                      <RestrictedRoute
                        component={AuthPage}
                        redirectTo="/calendar/online"
                        myProp="authReception"
                      />
                    }
                  >
                    <Route
                      path="signin"
                      element={
                        <RestrictedRoute
                          component={SigninForm}
                          redirectTo="/calendar/online"
                        />
                      }
                    />
                    <Route
                      path="register"
                      element={
                        <RestrictedRoute
                          component={RegisterForm}
                          redirectTo="/calendar/online"
                        />
                      }
                    />
                  </Route>
                  <Route
                    path="/success"
                    element={<SuccessfulRecordingPage />}
                  />
                  <Route path="/calendar" element={<CalendarPage />}>
                    <Route path="online" element={<OnlineCalendar />} />
                    <Route path="offline" element={<OfflineCalendar />} />
                  </Route>
                  <Route path="/price" element={<PricePage />} />
                  <Route path="/contacts" element={<ContactsPage />} />
                  <Route path="/about" element={<AboutMePage />} />
                  <Route
                    path="/cabinet"
                    element={
                      <PrivateRoute component={CabinetPage} redirectTo="/" />
                    }
                  />
                  <Route
                    path="/cabinet/register"
                    element={
                      // <PrivateRoute -- переробили бо вибивало на переході http://localhost:3000/dietary-planner
                      <RestrictedRoute // поки працює перевіряємо як буде
                        component={GoogleRegisterPage}
                        redirectTo="/cabinet"
                      />
                    }
                  />
                  <Route
                    path="/cabinet/personal"
                    element={
                      <PrivateRoute component={PersonalData} redirectTo="/" />
                    }
                  />
                  <Route
                    path="/cabinet/password"
                    element={
                      <PrivateRoute
                        component={ChangePasswordPage}
                        redirectTo="/"
                      />
                    }
                  />
                  <Route
                    path="/cabinet/finance"
                    element={
                      <PrivateRoute component={FinancePage} redirectTo="/" />
                    }
                  >
                    <Route
                      path="deposit"
                      element={
                        <PrivateRoute component={DepositAdmin} redirectTo="/" />
                      }
                    />

                    <Route
                      path="balance"
                      element={
                        <PrivateRoute component={BalanceAdmin} redirectTo="/" />
                      }
                    />
                  </Route>
                  <Route
                    path="/cabinet/finance/:userId"
                    element={
                      <PrivateRoute
                        component={AdminUsersHistory}
                        redirectTo="/"
                      />
                    }
                  />
                  <Route
                    path="/cabinet/finance/:userId/:appointmentId"
                    element={
                      <PrivateRoute
                        component={UpdateUserAppointmentDetails}
                        redirectTo="/"
                      />
                    }
                  />
                  <Route
                    path="/cabinet/reception"
                    element={
                      <PrivateRoute
                        component={admin ? AdminCabinetPage : Reception}
                        redirectTo="/"
                      />
                    }
                  />
                  <Route
                    path="/cabinet/reception/:appointmentId"
                    element={
                      <PrivateRoute
                        component={UpdateUserAppointmentDetails}
                        redirectTo="/"
                      />
                    }
                  />
                  {/* <Route
                  path="/cabinet/reception/:appointmentId"
                  element={<UpdateUserAppointmentDetails />}
                /> */}

                  {/* <Route
                  path="/cabinet/:appointmentId"
                  element={
                    <PrivateRoute
                      component={UpdateUserAppointmentDetails}
                      redirectTo="/"
                    />
                  }
                /> */}
                  <Route
                    path="/infoDeposit"
                    element={
                      <PrivateRoute component={AddDeposit} redirectTo="/" />
                    }
                  />
                  <Route path="*" element={<Navigate to="/" />} />
                </Route>
              </Routes>

              <ScrollToTopFab />
            </RadioBtnContext.Provider>
          </>
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
