import React from "react";
import Logo from "components/Logo";
import LogoGroup from "components/LogoGroup/LogoGroup";
import RightNav from "components/RightNav/RightNav";
import { Box } from "./Header.styled";

const Header = () => {
  return (
    <Box>
      <>
        <Logo />
        <RightNav />
        <LogoGroup />
      </>
    </Box>
  );
};

export default Header;
