// import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
// import { totalLogOut } from "redux/auth/operation";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate isLoading={null} persistor={persistor}>
        <HashRouter>
          <App />
        </HashRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// NOTE: new code reset login on jwt token expired
// const unauthorized = 401;
// const expired = 'jwt expired'
// const { dispatch } = store; // direct access to redux store.
// axios.interceptors.response.use(
//     response => response,
//     error => {
//         const { status } = error.response;
//         const { message } = error.response.data
//         console.log(message, 'errrrr')
//         if (status === unauthorized && message === expired) {
//             console.log('yahhooo!!')
//             dispatch(totalLogOut());
//         }
//         return Promise.reject(error);
//     }
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
