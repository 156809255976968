import { useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const usePasswordToggle = () => {
  const [showPass, setShowPass] = useState(false);
  const Icon = showPass ? (
    <VisibilityOffIcon
      sx={{ color: "#647F29", PaddingRight: "44px" }}
      onClick={() => {
        setShowPass((showPass) => !showPass);
      }}
    />
  ) : (
    <VisibilityIcon
      sx={{ color: "#647F29", PaddingRight: "44px" }}
      onClick={() => {
        setShowPass((showPass) => !showPass);
      }}
    />
  );
  const InputType = showPass ? "text" : "password";
  return [InputType, Icon];
};

export default usePasswordToggle;
