import React from "react";
import { NavLink } from "react-router-dom";
import Logos from "../images/logo.png";
import { LogoImg } from "./Header/Header.styled";

const Logo = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // робить прокрутку плавною
    });
  };
  return (
    <NavLink to="/">
      <LogoImg
        src={Logos}
        alt="логотип"
        height="40px"
        width="40px"
        onClick={scrollToTop}
      />
    </NavLink>
  );
};

export default Logo;
