import styled from "@emotion/styled";

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  z-index: 6;
  /* background-color: ${(p) => p.theme.palette.primary.bg}; */
  background: rgba(211, 229, 184, 0.65);

  overflow: hidden;
  /* background-color: transparent; */
`;
