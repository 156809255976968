// import React, { useEffect } from "react";
// import { Box } from "@mui/material";

import useGoogleFetch from "hooks/useGoogleFetch";
import { Button, buttonGoogle } from "./googleButton.styled";

// NOTE: new
// import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";

const GoogleButton = ({ children }) => {
  // const { handleGoogle, loading } = useGoogleFetch()
  const { handleGoogle } = useGoogleFetch();

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      // console.log(tokenResponse);
      handleGoogle(tokenResponse);
    },
  });
  // const { handleGoogle, loading } = useGoogleFetch(
  //   // "https://dietary-rest-api.yurgo.fun/api/google/redirect"
  //   `${process.env.REACT_APP_BACK_BASE_URL}/google/redirect`
  // );

  // const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  // // "233361330937-7o9ui8snhdctmvmng418eompkqi612m9.apps.googleusercontent.com";

  // // console.log(REACT_APP_GOOGLE_CLIENT_ID)

  // useEffect(() => {
  //   /* global google */
  //   if (window.google) {
  //     google.accounts.id.initialize({
  //       // client_id: REACT_APP_REACT_APP_GOOGLE_CLIENT_ID,
  //       client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //       callback: handleGoogle,
  //     });

  //     if (document.getElementById("loginDiv")) {
  //       google.accounts.id.renderButton(document.getElementById("loginDiv"), {
  //         // type: 'icon',
  //         type: "standard",
  //         theme: "outline",
  //         size: "large",
  //         // size: 'medium',
  //         text: "signin with",
  //         shape: "pill",
  //         // shape: 'circle',
  //         logo_alignment: "left",
  //         // logo_alignment: 'center',
  //         width: 320,
  //         // color: 'green',
  //       });
  //     }
  //   }
  // }, [handleGoogle, REACT_APP_GOOGLE_CLIENT_ID]);
  // TODO: error && <p style ... displayed ion page for a sec

  const googleClick = () => {
    login();
  };

  return (
    // <Box sx={{ display: "flex", justifyContent: "center", height: "40px" }}>
    //   {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
    //   {/* {error && alert(error)} */}
    //   {loading ? <div>Loading....</div> : <div id="loginDiv"></div>}
    // </Box>
    <>
      <Button
        sx={buttonGoogle}
        variant="contained"
        fullWidth
        type="submit"
        onClick={googleClick}
      >
        {children}
      </Button>
    </>
  );
};

export default GoogleButton;
