import styled from "@emotion/styled";

import { ReactComponent as Click } from "../../images/click.svg";
import { ReactComponent as Telegram } from "../../images/contacts/telegram.svg";
import { ReactComponent as Viber } from "../../images/contacts/viber.svg";
import { ReactComponent as Close } from "../../images/icon-close.svg";

// background: rgba(211, 229, 184, 0.65);   // для заднього фону на модалках

export const ModalContainer = styled.div`
  width: 266px;
  padding: 24px 22px 24px 22px;
  border-radius: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: ${(p) => p.theme.palette.primary.btn};

  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* transition: cubic-bezier(0.075, 0.82, 0.165, 1); */
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

export const ModalT = styled.span`
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[6]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 1.4;
  text-align: center;
  color: ${(p) => p.theme.palette.primary.secondaryText};
`;

export const ModalText = styled.p`
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  line-height: 16px;
  text-align: center;
  color: ${(p) => p.theme.palette.primary.secondaryText};
`;

export const Text = styled.span`
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[4]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 32px;
  text-align: center;
  color: ${(p) => p.theme.palette.primary.secondaryText};
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
`;

export const ModalBtn = styled.button`
  width: 90px;
  height: 100%;
  padding: 12px 8px;
  border-radius: 12px;
  text-align: center;
  color: ${(p) => p.theme.palette.primary.text};
  background-color: ${(p) => p.theme.palette.primary.white};
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[3]};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  line-height: 16px;

  &.hover,
  &.focus {
    background-color: ${(p) => p.theme.palette.primary.details};
    color: ${(p) => p.theme.palette.primary.secondaryText};
  }

  &:first-of-type {
    margin-right: 5px;
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  z-index: 4;
  right: -5px;
  top: -20px;
  width: 30px;
  height: 30px;
  background-color: transparent;
`;

export const IconClick = styled(Click)`
  display: inline;
  cursor: pointer;
  margin-bottom: 20px;
  width: 32px;
  height: 32px;
`;
export const MessengersUl = styled.ul`
  display: flex;
  margin-top: 6px;
  list-style: none;
  padding: 0;
`;
export const MessengersLi = styled.li`
  :first-child {
    margin-right: 32px;
  }
`;
export const MessengersLink = styled.a`
  display: flex;
  align-items: center;
`;

export const TelegramIcon = styled(Telegram)`
  /* display: inline; */
  /* margin: auto; */
  cursor: pointer;
  /* margin-right: 32px; */
  width: 26px;
  height: 26px;
  fill: #fff;
  :hover,
  :focus {
    fill: ${(p) => p.theme.palette.primary.details};
  }
`;

export const ViberIcon = styled(Viber)`
  /* display: inline; */
  cursor: pointer;
  /* margin: auto; */
  width: 24px;
  height: 24px;
  fill: #fff;
  :hover,
  :focus {
    fill: ${(p) => p.theme.palette.primary.details};
  }
`;

export const CloseIcon = styled(Close)`
  display: inline;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;
