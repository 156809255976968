import { createTheme } from "@mui/material";
// import { ukUA as dataGridukUA } from "@mui/x-data-grid";
// import { ukUA as coreukUA } from "@mui/material/locale";
// import { ukUA } from "@mui/x-date-pickers/locales";

export const theme = createTheme({
  fonts: {
    DMSans: "'DM Sans', sans-serif",
    Comfortaa: "'Comfortaa', cursive",
    Manrope: "'Manrope', sans-serif",
    Inter: "'Inter', sans-serif",
    Barlow: "'Barlow', sans-serif",
  },

  typography: {
    fontFamily: [
      "'DM Sans', sans-serif, 'Comfortaa', cursive,'Manrope', sans-serif,'Inter', sans-serif, 'Barlow', sans-serif",
    ].join(","),
  },

  fontSizes: [
    "8px", // 0
    "10px", // 1
    "12px", // 2
    "14px", // 3
    "16px", // 4
    "18px", // 5
    "20px", // 6
    "24px", // 7
    "28px", // 8
    "32px", // 9
    "44px", // 10
    "60px", // 11
    "72px", // 12
    "100px", // 13
  ],

  fontWeights: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },

  shadows: [
    "0px 3px 8px rgba(76, 95, 120, 0.12), 0px 3px 1px rgba(76, 95, 120, 0.04)", // btn active
    ...Array(24).fill("none"), // add more values to the shadows array
  ],

  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",

  borderRadius: [
    "4px", // 0
    "12px", // 1
    "20px", // 2
    "24px", // 3
    "0px 24px 24px 0px", // 4
    "24px 0px 0px 24px", // 5
    "50%", // 6
  ],

  borderBottom: "0.5px solid #E4E9D8",

  border: [
    " 0.5px solid #647F29", // 0 - чекбокс
    " 0.5px solid #FBB336", // 1 - чекбокс active
    " 0.5px solid rgba(0, 0, 0, 0.04)", // 2 - active login
    "1px solid #647F29", // 3 - mobile input login
    " 1px solid #EA4335", // 4 - mobile input login error
    "1px solid #486284", // 5 - mobile btn active онлайн-офлайн
    "1px solid #E8EBE4", // 6 - mobile btn онлайн-офлайн
    " 2px solid #FBB336", // 7 - чекбокс active 2px
  ],

  device: {
    mobile: "(min-width: 375px)",
    tablet: "(min-width: 768px)",
    desktop: "(min-width: 1440px)",
  },

  breakpoints: {
    values: {
      mobile: 375,
      tablet: 768,
      desktop: 1200,
    },
  },

  media: MainDeviceRatio,

  palette: {
    primary: {
      main: "#647F29",
      text: "#404731",
      secondaryText: "#ffffff",
      bg: "#F7FAF3",
      bgDetails: "#E4E9D8",
      btn: " #85B53A",
      details: "#FBB336",
      error: "#EA4335",
      btnSecondary: "#A7A8A6",
      btnPrimaryBg: "#E8EBE4",
      btnPrimary: "#BFC0BE",
      white: "#fbfbfb",
      selectListTime: "#D3E5B8",
    },
  },

  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: "#8BAA36",
        },
      },
    },
  },
});

function MainDeviceRatio(url1, url2, url3, url4, url5) {
  return `@media (min-device-pixel-ratio: 2),
  (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    background-image: url('${url1}'), url('${url2}'), url('${url3}'), url('${url4}'), url('${url5}')}`;
}
