import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import background from "../../images/bg-image.webp";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as Menu } from "../../images/menu.svg";

export const LinkNav = styled(NavLink)`
  font-size: ${(p) => p.theme.fontSizes[7]};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  line-height: 32px;
  color: ${(p) => p.theme.palette.primary.main};

  @media ${(p) => p.theme.device.tablet} {
    font-size: ${(p) => p.theme.fontSizes[7]};
    line-height: calc(
      (${(p) => p.theme.fontWeights.medium}) / (${(p) => p.theme.fontSizes[7]})
    );
  }

  @media ${(p) => p.theme.device.desktop} {
    font-weight: ${(p) => p.theme.fontWeights.medium};
    font-size: ${(p) => p.theme.fontSizes[3]};
    line-height: calc(
      (${(p) => p.theme.fontWeights.medium}) / (${(p) => p.theme.fontSizes[3]})
    );
  }

  &.active {
    color: rgba(139, 170, 54, 1);
  }
`;

export const NavBarExtendedContainer = styled.div`
  top: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  padding: 24px 24px 0 24px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${(p) => p.theme.palette.primary.bg};
  z-index: 4;
  overflow: hidden;

  @media ${(p) => p.theme.device.tablet} {
    padding: 56px 56px 0px 56px;
    right: -10.5px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 30px;
    right: 0px;
    width: 110%;
    height: 100%;
    background-image: url(${background});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    opacity: 0.2;
    z-index: -2;

    @media ${(p) => p.theme.device.tablet} {
      bottom: 0;
      right: 0;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: right bottom -134px;
    }
    @media screen and (min-width: 1000px) {
      background-size: cover;
    }

    @media ${(p) => p.theme.device.desktop} {
      display: none;
    }
  }
`;

export const NavExBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 228px;
  margin-top: 65px;
  border-top: 1px solid #e4e9d8;
`;

export const NavEx = styled.nav`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @media ${(p) => p.theme.device.tablet} {
    margin-left: 24px;
  }
`;

export const OpenLinksButton = styled.button`
  position: absolute;
  top: 28px;
  right: 24px;
  z-index: 5;
  width: 32px;
  height: 32px;
  background-color: transparent;

  @media ${(p) => p.theme.device.tablet} {
    width: 24px;
    height: 24px;
    top: 28px;
  }

  @media ${(p) => p.theme.device.desktop} {
    display: none;
  }
`;

export const IconBurger = styled(Menu)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${(p) => p.theme.palette.primary.main};
`;

export const IconClose = styled(CloseIcon)`
  width: 32px;
  height: 32px;
  cursor: pointer;
  color: ${(p) => p.theme.palette.primary.main};
`;
