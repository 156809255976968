import React from "react";
import BurgerMenu from "components/BurgerMenu/BurgerMenu";
import LogoUser from "components/LogoUser/LogoUser";
import { BoxLogo } from "./LogoGroup.styled";

const LogoGroup = () => {
  return (
    <BoxLogo>
      <LogoUser />
      <BurgerMenu />
    </BoxLogo>
  );
};

export default LogoGroup;
