import styled from "@emotion/styled";
import background from "../images/bg-image.webp";
import backgroundDesktop from "../images/bg_desktop.png";

export const StyledMain = styled.main`
  position: relative;
  width: 100%;
  min-height: 450px;
  /* height: 100%; */

  padding-left: 24px;
  padding-right: 24px;
  overflow: hidden;

  @media screen and (min-width: 575px) {
    min-height: 593px;
  }
  @media ${(p) => p.theme.device.tablet} {
    min-height: 620px;
    padding-left: 32px;
    padding-right: 32px;
  }
  @media screen and (min-width: 770px) {
    min-height: 690px;
  }
  @media screen and (min-width: 870px) {
    min-height: 760px;
  }
  @media ${(p) => p.theme.device.desktop} {
    min-height: 751px;
    padding-left: 80px;
    padding-right: 0px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url(${background});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;

    opacity: 0.2;
    z-index: -1;

    @media ${(p) => p.theme.device.tablet} {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left bottom -134px;
    }

    @media screen and (min-width: 1000px) {
      background-size: cover;
    }

    @media ${(p) => p.theme.device.desktop} {
      opacity: 1;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right 0 top 0;
      background-image: url(${backgroundDesktop});
    }
  }
`;
