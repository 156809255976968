import React from "react";

import {
  ModalContainer,
  ModalText,
  MessengersUl,
  TelegramIcon,
  ViberIcon,
  CloseIcon,
  CloseBtn,
  MessengersLi,
  MessengersLink,
} from "./ModalWindow.styled";

const ModalResetPassword = ({ onCloseModal }) => {
  return (
    <>
      <ModalContainer
        style={{
          padding: "16px 32px",
        }}
      >
        <ModalText style={{ fontWeight: 700 }}>
          Для відновлення паролю
        </ModalText>
        <ModalText>перейдіть до</ModalText>
        <ModalText
          style={{
            letterSpacing: "-0.01em",
          }}
        >
          <strong>Телеграм</strong> або <strong>Viber</strong> - бота,
        </ModalText>
        <ModalText>та виберіть в меню</ModalText>
        <ModalText>
          <strong>"Відновити пароль"</strong>
        </ModalText>

        <MessengersUl>
          <MessengersLi>
            <MessengersLink href={process.env.REACT_APP_TELEGRAM_BOT_LINK}>
              <TelegramIcon />
            </MessengersLink>
          </MessengersLi>
          <MessengersLi>
            <MessengersLink href={process.env.REACT_APP_VIBER_BOT_LINK}>
              <ViberIcon />
            </MessengersLink>
          </MessengersLi>
        </MessengersUl>
        <CloseBtn type="button" onClick={onCloseModal}>
          <CloseIcon />
        </CloseBtn>
      </ModalContainer>
    </>
  );
};

export default ModalResetPassword;
