import { useRadioBtn } from "context/radioBtnContext";
import {
  Box,
  RadioContainer,
  RadioInput,
  RadioLable,
} from "./DiagnosisRadioBtn.styled";

const DiagnosisRadioBtn = ({ onDiagnosisSet }) => {
  const { radioChoice } = useRadioBtn();

  const isCheck = radioChoice === "secondaryShot" ? true : false;

  return (
    <Box role="group" aria-labelledby="my-radio-group">
      <RadioContainer>
        <RadioInput
          type="radio"
          name="diagnosisRadio"
          value="secondaryLong"
          onChange={(e) => onDiagnosisSet(e.target.value)}
        />

        <RadioLable>З діагностикою</RadioLable>
      </RadioContainer>
      <RadioContainer>
        <RadioInput
          type="radio"
          name="diagnosisRadio"
          value="secondaryShot"
          onChange={(e) => onDiagnosisSet(e.target.value)}
          checked={isCheck}
        />

        <RadioLable>Без діагностики</RadioLable>
      </RadioContainer>
    </Box>
  );
};

export default DiagnosisRadioBtn;
