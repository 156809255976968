import * as React from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/uk";

import CustomAdapter from "../../helpers/CustomDateAdapter";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { useSelector } from "react-redux";
import { selectLastDate } from "redux/dates/selector";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.some((d) => dayjs(d, "MM-DD-YYYY").isSame(day, "day"));

  const customStyles = {
    backgroundColor: isSelected ? "#FBB336" : "inherite",
    borderRadius: "50%",
    color: isSelected ? "#fff" : "#647F29",
  };

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      sx={customStyles}
    />
  );
}

const MuiCalendar = ({
  disabled,
  value,
  onSetValue,
  highlightedDates,
  isAdmin = true,
}) => {
  // const notDisabled = isAdmin ? 60 : 180;
  const lastDate = useSelector(selectLastDate);

  const disabledDays = (date) => {
    const disabledDates = disabled.map((d) => dayjs(d, "MM-DD-YYYY"));
    // const twoMonthsFromNow = new Date(
    //   dayjs().add(60, "day").startOf("day").format("MM-DD-YYYY")
    // );
    // const intervalActiveDays = new Date(lastDate);

    const intervalActiveDays = dayjs(lastDate);

    // const result =
    //   disabledDates.some((d) => d.isSame(date, "day")) ||
    //   intervalActiveDays < date;

    return (
      disabledDates.some((d) => d.isSame(date, "day")) ||
      date.isAfter(intervalActiveDays, "day")
    );
  };

  return (
    <LocalizationProvider dateAdapter={CustomAdapter} adapterLocale="uk">
      <DemoContainer
        components={["DateCalendar"]}
        sx={{
          alignItems: "center",
        }}
      >
        <DemoItem>
          <DateCalendar
            value={value}
            disablePast={isAdmin}
            shouldDisableDate={disabledDays}
            onChange={(newValue) => onSetValue(newValue)}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              day: {
                highlightedDays: highlightedDates,
              },
            }}
            sx={{
              ".MuiStack-root:has(&)": {
                "@media (min-width: 768px)": {
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 60px 0px",
                  borderRadius: "24px",
                  paddingTop: "0px",
                  width: "414px",
                  marginLeft: "auto",
                  marginRight: "auto",
                },
                "@media (min-width: 1440px)": {
                  margin: "0px",
                },
              },
              width: "100%",
              height: "300px",
              backgroundColor: "#FBFBFB",
              border: "1px solid #E8E8E1",
              borderRadius: "24px",

              "@media (min-width: 375px)": {
                width: "326px",
                height: "330px",
              },
              "@media (min-width: 768px)": {
                width: "414px",
                backgroundColor: "#FFFFFF",
              },
              "@media (min-width: 1440px)": {
                height: "360px",
              },
              "& .MuiDayCalendar-header": {
                "@media (min-width: 768px)": {
                  gap: "16px",
                },
              },
              "& .MuiPickersCalendarHeader-label": {
                textTransform: "capitalize",
                color: "#85B53A",
                fontWeight: "600",
                fontSize: "15px",
                lineHeight: "1.29",
                "@media (min-width: 375px)": {
                  fontSize: "17px",
                },
              },
              "& .MuiDayCalendar-weekContainer": {
                "@media (min-width: 768px)": {
                  gap: "16px",
                },
                "@media (min-width: 1440px)": {
                  margin: "7px 0",
                },
              },
              "& .MuiDayCalendar-weekDayLabel ": {
                textTransform: "capitalize",
                color: "#85B53A",
                fontWeight: "600",
                fontSize: "11px",
                lineHeight: "1.38",

                width: "32px",
                height: "32px",

                "@media (min-width: 375px)": {
                  fontSize: "13px",
                  width: "36px",
                  height: "36px",
                },
              },
              "& .MuiPickersCalendarHeader-switchViewIcon": {
                color: "#647F29",
              },
              "& .MuiSvgIcon-root": {
                fill: "#647F29",
                width: "28px",
                height: "40px",
              },
              "& .MuiButtonBase-root": {
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "1.25",
                width: "32px",
                height: "32px",

                "@media (min-width: 375px)": {
                  fontSize: "20px",
                  width: "36px",
                  height: "36px",
                },

                "&.Mui-selected ": {
                  background: "#FBB336",
                  border: "2px solid #647F29 ",
                  borderRadius: "100px",
                  color: "#ffffff",

                  "&:hover": {
                    border: "2px solid #647F29 ",
                    background: "#FBB336",
                    borderRadius: "100px",
                    color: "#ffffff",
                  },
                  "&:focus": {
                    border: "2px solid #647F29 ",
                    background: "#FBB336",
                    // borderRadius: "100px",
                    color: "#ffffff",
                  },
                },
                "&.MuiPickersDay-root.Mui-disabled:not(.Mui-selected)": {
                  color: "#D3E5B8",
                  fontWeight: "400",
                },
                "&.MuiPickersDay-today:not(.Mui-selected)": {
                  backgroundColor: "#647F29",
                  border: "none",
                  color: "#fff",
                },
              },
              "& .MuiYearCalendar-root": {
                width: "100%",
              },
            }}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default MuiCalendar;
