import { useAuth } from "hooks/useAuth";
import { Navigate } from "react-router-dom";

const RestrictedRoute = ({
  component: Component,
  redirectTo = "/",
  myProp,
}) => {
  const { isLoggedIn, user } = useAuth();
  const isAllUserData = user.phone && user.birthday;

  if (isLoggedIn && isAllUserData) {
    return <Navigate to={redirectTo} />;
  }

  if (isLoggedIn && !isAllUserData) {
    <Navigate to={"/cabinet/register"} />;
  }

  return <Component myProp={myProp} />;
};

export default RestrictedRoute;
