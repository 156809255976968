import React, { useState, useEffect } from "react";
import { Box } from "./PopUpNotification.style";

export const PopUpNotification = ({ children, timeout }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, timeout);
    return () => {
      clearTimeout(timer);
    };
  }, [timeout]);

  if (!visible) return null;
  return <Box visible={visible}>{children}</Box>;
};
