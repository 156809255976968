import useGetHoursList from "hooks/useGetHoursList ";
import { NoList } from "./TimePicker.styled";

import RadioGroup from "./RadioGroup";

const TimePicker = ({
  setRadioChoice,
  selectDay,
  value,
  appType,
  radioChoice,
}) => {
  const hoursList = useGetHoursList(value, selectDay, radioChoice, appType);

  return (
    <>
      {hoursList.length > 0 ? (
        <form>
          <RadioGroup
            options={hoursList}
            onChange={(e) => setRadioChoice(e.target.value)}
          />
        </form>
      ) : (
        <NoList>Виберіть дату</NoList>
      )}
    </>
  );
};

export default TimePicker;
