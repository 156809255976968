import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authReducer } from "./auth/authSlice";
import { appointmentsReducer } from "./appointments/appointmentsSlice";
import { filtersReducer } from "./filter/filterSlice";
import { datesReducer } from "./dates/datesSlice";
import { axiosMiddleware } from "hooks/useJwtExpired";
import { historyReducer } from "./history/historySlice";
import { queryReducer } from "./query/querySlice";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["token", "isLoggedIn"],
};

export const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    appointments: appointmentsReducer,
    filters: filtersReducer,
    dates: datesReducer,
    history: historyReducer,
    query: queryReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(axiosMiddleware),

  devTools: process.env.NODE_ENV === "development",
});

export const persistor = persistStore(store);
