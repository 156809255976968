import styled from "@emotion/styled";

export const buttonStyles = {
  width: "100%",
  height: "48px",
  marginTop: "72px",
  borderRadius: "24px",
  ml: "auto",
  backgroundColor: "#A7A8A6",
  color: "#FFFFFF",
  [`&:hover`]: {
    backgroundColor: "#85B53A",
  },
  [`&:focus`]: {
    backgroundColor: "#85B53A",
  },
};

export const buttonGoogle = {
  display: "inline-flex", //TODO: new
  alignItems: "center", //TODO: new
  paddingLeft: "5px", //TODO: new
  width: "100%",
  height: "48px",
  borderRadius: "24px",
  border: "1px solid #FBB336",
  ml: "auto",
  backgroundColor: "#FFFFFF",
  color: "#404731",
  [`&:hover`]: {
    backgroundColor: "#85B53A",
    color: "#FFFFFF",
  },
  [`&:focus`]: {
    backgroundColor: "#85B53A",
    color: "#FFFFFF",
  },
};

export const Button = styled.button`
  position: relative;
  font-family: ${(p) => p.theme.fonts.Manrope};
  font-size: ${(p) => p.theme.fontSizes[3]};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  line-height: 32px;

  width: 100%;
  max-width: 327px;
  padding: 8px;
  margin: 0 auto;
  box-shadow: 0px 4px 20px 0px rgba(100, 127, 41, 0.25);
  border: 1px solid #fbb336;
  background: #ffffff;
  border-radius: 24px;
  color: #404731;

  &:disabled {
    background: #a7a8a6;
  }

  @media ${(p) => p.theme.device.tablet} {
    max-width: 414px;
  }
  @media ${(p) => p.theme.device.desktop} {
    max-width: 522px;
  }
  &:hover,
  &:focus {
    border-color: #85b53a;
  }
`;
