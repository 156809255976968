import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const getDates = createAsyncThunk("dates/get", async (_, thunkAPI) => {
  try {
    const r = await axios.get("/date");
    return r.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const postDates = createAsyncThunk(
  "dates/post",
  async (credentials, thunkAPI) => {
    try {
      const r = await axios.post("/date/set-weekends", credentials);
      if (r.data) {
        toast.success("Оновлення пройшло успішно");
      }
      return r.data;
    } catch (error) {
      if (error?.response?.status === 409) {
        toast.error("Є прийоми через які неможливо зробити відпустку!");
      } else {
        toast.error(error?.response?.data?.message);
      }

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
