import React from "react";
import {
  RadioContainer,
  TimeLabel,
  TimeInput,
  TimeSpan,
} from "./TimePicker.styled";
import { useRadioBtn } from "context/radioBtnContext";

const RadioGroup = ({ options, onChange }) => {
  const { appointmentRadioChoice } = useRadioBtn();
  return (
    <RadioContainer role="group" aria-labelledby="my-radio-group">
      {options.map((item, index) => (
        <TimeLabel key={index}>
          <TimeInput
            type="radio"
            name="radioChoice"
            value={item.label}
            onChange={onChange}
            disabled={item.isDisabled}
            checked={appointmentRadioChoice === item.label}
          />
          <TimeSpan>{item.label}</TimeSpan>
        </TimeLabel>
      ))}
    </RadioContainer>
  );
};

export default RadioGroup;
